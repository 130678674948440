import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'

const initialState = {
  basicBanner: null,
  premiumBanner: null,
  courseOnlyBanner: null,
  loading: false,
  error: null
}

export const getBannerByType = createAsyncThunk(
  'dashboard-banner/get-banner-by-type',
  async ({ type }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/dashboard-banner/${type}`)
      return { banner: data, type }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const getBanners = createAsyncThunk(
  'dashboard-banner/get-banners',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/dashboard-banner/read-many`)
      return { banners: data }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const upsertBanner = createAsyncThunk(
  'dashboard-banner/upsert',
  async ({ banner }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/api/dashboard-banner`, {
        title: banner.title,
        content: banner.content,
        video_url: banner.video_url,
        button_text: banner.button_text,
        button_url: banner.button_url,
        type: banner.type
      })
      return { banner: data }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeBanner = createAsyncThunk(
  'dashboard-banner/remove-banner',
  async ({ type }, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/dashboard-banner/${type}`)

      return type
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboardBanner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBannerByType.pending, (state) => {
        state.loading = true
      })
      .addCase(getBannerByType.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.type === 'premium') {
          state.premiumBanner = action.payload.banner.banner
        } else state.basicBanner = action.payload.banner.banner
      })
      .addCase(getBannerByType.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(getBanners.pending, (state) => {
        state.loading = true
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.loading = false
        state.premiumBanner = action.payload.banners.premium
        state.basicBanner = action.payload.banners.basic
        state.courseOnlyBanner = action.payload.banners.courseOnly
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(upsertBanner.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertBanner.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.banner.banner.type === 'premium') {
          state.premiumBanner = action.payload.banner.banner
        } else if (action.payload.banner.banner.type === 'basic') {
          state.basicBanner = action.payload.banner.banner
        } else if (action.payload.banner.banner.type === 'course only') {
          state.courseOnlyBanner = action.payload.banner.banner
        }

        if (action.payload.banner.isNew) toast.success(`Banner created successfully`)
        else toast.success(`Banner updated successfully`)
      })
      .addCase(upsertBanner.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(removeBanner.pending, (state) => {
        state.loading = true
      })
      .addCase(removeBanner.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload === 'premium') state.premiumBanner = null
        else if (action.payload === 'basic') state.basicBanner = null
        else if (action.payload === 'course only') state.courseOnlyBanner = null

        toast.success(`Banner deleted successfully`)
      })
      .addCase(removeBanner.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export default dashboardSlice.reducer
