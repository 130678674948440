import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import SecondaryButton from '../../components/general/SecondaryButton'
import FormField from '../../utils/FormField'
import { clearError, upsertModuleAsync } from './courseSlice'
import Select, { components } from 'react-select'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

function arrayMove(array, from, to) {
  const slicedArray = array.slice()
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0])
  return slicedArray
}

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = { ...props.innerProps, onMouseDown }
  return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableMultiValueLabel = SortableHandle((props) => <components.MultiValueLabel {...props} />)

const SortableSelect = SortableContainer(Select)

function Checkbox({ value, onChange, label, name }) {
  return (
    <div className="flex items-center h-5">
      <input
        id={name}
        name={name}
        type="checkbox"
        className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
        checked={value || false} // default to false to avoid warning in case undefined was passed
        onChange={onChange}
      />
      <div className="ml-3 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700">
          {label}
        </label>
      </div>
    </div>
  )
}

const ModuleForm = ({ setState, setOpen, editingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { error, lessonOptions } = useSelector((state) => state.course)
  const { tags } = useSelector((state) => state.tag)

  useEffect(() => {
    if (editingItem) setFormValues({ ...editingItem })
    else setFormValues({ position: 0, visibleToBasic: true, visibleToPremium: true })
  }, [editingItem])

  const lessonsOptions = lessonOptions
    ?.filter((lesson) => lesson.ModuleId === null || lesson.ModuleId === formValues.id)
    .map((lesson) => ({
      value: lesson.id,
      label: lesson.title
    }))
  const lessonsValue = formValues?.Lessons?.map((lesson) => ({
    value: lesson.id,
    label: lesson.title
  }))

  const onChange = (event) => {
    event.preventDefault()
    let { name, value, checked } = event.target
    const isOneOfCheckboxInputs = ['visibleToBasic', 'visibleToPremium'].includes(name)
    if (isOneOfCheckboxInputs) value = checked // boolean instead of string
    error && error[name] && dispatch(clearError({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let Lessons = arrayMove(formValues?.Lessons, oldIndex, newIndex)
    Lessons = Lessons.map((lesson, i) => ({ ...lesson, position: i }))
    setFormValues((prevState) => ({
      ...prevState,
      Lessons
    }))
  }

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit module: ' + formValues.title : 'Add a new module'}
      </h3>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          await dispatch(upsertModuleAsync({ module: formValues }))
          setOpen(false)
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Module title"
                field="title"
                error={error && error['title']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>
          <div className="sm:col-span-3 pt-6">
            <Checkbox
              value={formValues.visibleToBasic}
              onChange={onChange}
              label="Visible to basic users"
              name="visibleToBasic"
            />
            <Checkbox
              value={formValues.visibleToPremium}
              onChange={onChange}
              label="Visible to premium users"
              name="visibleToPremium"
            />
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="lesson" className="block text-sm font-medium text-gray-700">
              Lessons (sortable)
            </label>
            <div className="mt-1">
              <SortableSelect
                id="lessons"
                useDragHandle
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                // react-select props:
                isMulti
                options={lessonsOptions}
                value={lessonsValue}
                onChange={(Lessons) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    Lessons: Lessons.map((lesson) => ({
                      id: lesson.value,
                      title: lesson.label
                    }))
                  }))
                }
                components={{
                  // @ts-ignore We're failing to provide a required index prop to SortableElement
                  MultiValue: SortableMultiValue,
                  MultiValueLabel: SortableMultiValueLabel
                }}
                closeMenuOnSelect={false}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
              Required Tags
            </label>
            <div className="mt-1">
              <Select
                id="tags"
                isMulti={true}
                value={formValues?.Tags?.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
                onChange={(Tags) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    Tags: Tags.map((tag) => ({
                      id: tag.value,
                      name: tag.label
                    }))
                  }))
                }
                options={tags.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
              />
            </div>
          </div>
        </div>

        <div className="mt-24 py-3 sm:flex sm:justify-between">
          <div>
            {editingItem?.id && (
              <button
                type="button"
                className="uppercase w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="w-2/3 flex flex-1 gap-4 flex-grow-0 items-start">
            <PrimaryButton extraClasses="uppercase sm:w-auto sm:ml-3" label="Save" type="submit" />

            <SecondaryButton
              extraClasses="uppercase"
              label="Cancel"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ModuleForm
