import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import PrimaryButton from '../../components/general/PrimaryButton'
import SecondaryButton from '../../components/general/SecondaryButton'
import { reorderModulesAsync } from './courseSlice'

function arrayMove(array, from, to) {
  const slicedArray = array.slice()
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0])
  return slicedArray
}

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = { ...props.innerProps, onMouseDown }
  return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableMultiValueLabel = SortableHandle((props) => <components.MultiValueLabel {...props} />)

const SortableSelect = SortableContainer(Select)

const styles = {
  multiValueRemove: (base) => {
    return { ...base, display: 'none' }
  }
}

const ReorderModulesForm = ({ setOpen }) => {
  const dispatch = useDispatch()
  const [modulesSorted, setModulesSorted] = useState([])
  const { modules } = useSelector((state) => state.course)

  useEffect(() => {
    if (modules) {
      setModulesSorted(modules)
    }
  }, [modules])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let modules = arrayMove(modulesSorted, oldIndex, newIndex)
    modules = modules.map((module, i) => ({ ...module, position: i }))
    setModulesSorted(modules)
  }

  return (
    <div>
      <h3 className="text-2xl">Reorder modules</h3>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          await dispatch(reorderModulesAsync({ modules: modulesSorted }))
          setOpen(false)
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Modules (sortable)
            </label>
            <div className="mt-1">
              <SortableSelect
                useDragHandle
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                // react-select props:
                isMulti
                options={modulesSorted?.map((module) => ({
                  value: module.id,
                  label: module.title
                }))}
                value={modulesSorted?.map((module) => ({
                  value: module.id,
                  label: module.title
                }))}
                onChange={(modules) =>
                  setModulesSorted(
                    modules.map((module) => ({
                      id: module.value,
                      title: module.label
                    }))
                  )
                }
                components={{
                  // @ts-ignore We're failing to provide a required index prop to SortableElement
                  MultiValue: SortableMultiValue,
                  MultiValueLabel: SortableMultiValueLabel
                }}
                closeMenuOnSelect={false}
                styles={styles}
                isClearable={false}
              />
            </div>
          </div>
        </div>

        <div className="mt-24 py-3 sm:flex sm:justify-between">
          <div></div>
          <div className="flex">
            <PrimaryButton extraClasses="sm:w-auto sm:ml-3 uppercase" label="Save" type="submit" />

            <SecondaryButton
              label="Cancel"
              extraClasses="sm:ml-3 uppercase"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ReorderModulesForm
