import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import NoAccess from '../general/NoAccess'

function PrivateRoute({ children, requiredRoles, ...rest }) {
  const { currentUser, loading } = useSelector((state) => state.auth)

  const isPublicRoute = requiredRoles.includes('public')
  requiredRoles.push('admin') // admins can access everything
  const userHasRequiredRole = currentUser && requiredRoles.includes(currentUser?.role)
  const hasAccess = isPublicRoute || userHasRequiredRole

  const isBlocked = getIsUserBlockedByStripeStatus(currentUser)

  if (loading) return null
  if (!currentUser) return <Redirect to="/signin" />
  if (isBlocked) return <Redirect to="/blocked" />
  return <Route {...rest} render={() => (hasAccess ? children : <NoAccess />)} />
}

function getIsUserBlockedByStripeStatus(user) {
  if (user?.alwaysGrantAccess) return false

  const badStripeStatuses = ['unpaid', 'incomplete', 'incomplete_expired', 'past_due']
  const userHasBadStripeStatus = badStripeStatuses.includes(user?.stripeStatus)
  return userHasBadStripeStatus
}

export default PrivateRoute
