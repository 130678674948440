import React from 'react'
import PrimaryButton from '../../../components/general/PrimaryButton'
import { PencilIcon } from '@heroicons/react/outline'
import MetricItem from '../MetricItem'

const PromotionTab = ({ products, setOpen, setEditingItem }) => {
  return (
    <div>
      {products?.map((product) => (
        <div key={product.id} className="flex flex-col sm:flex-row sm:space-x-4 mb-10">
          <div className="w-full sm:w-3/5 rounded-md border py-6 px-6">
            <div className="flex justify-between items-center">
              <h1 className="text-4xl">{product.title}</h1>
              <button
                onClick={() => {
                  setEditingItem(product)
                  setOpen(true)
                }}
                className="inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
              >
                <PencilIcon className="h-5 w-5 mr-2" />
                edit
              </button>
            </div>
            <p className="mt-6">{product.description}</p>
          </div>
          <div className="w-full sm:w-2/5 rounded-md border flex flex-col items-center justify-center py-4 px-2">
            <div className="flex items-center">
              <MetricItem
                itemName="Promotion Cost"
                itemValue={product.promotionCost}
                dollar={true}
              />
              <div className="h-12 border-r border-black"></div>
              <MetricItem
                itemName="Promotion Revenue"
                itemValue={product.promotionRevenue}
                dollar={true}
              />
            </div>
            <div className="w-32 my-2">
              <PrimaryButton
                label="Edit Metrics"
                onClick={() => {
                  setEditingItem(product)
                  setOpen(true)
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PromotionTab
