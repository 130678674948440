import React from 'react'

const SecondaryButton = ({
  label,
  size = 'md',
  type = 'button',
  onClick = () => {},
  extraClasses,
  ...rest
}) => {
  const sizeClasses = size === 'md' ? 'py-2 px-4' : 'py-1'

  return (
    <button
      type={type}
      className={`hover:bg-gray-100 transition-colors ${sizeClasses} rounded-md ${extraClasses}`}
      onClick={onClick}
      {...rest}
    >
      {label}
    </button>
  )
}

export default SecondaryButton
