import React, { useState } from 'react'
import { LinkIcon, PaperClipIcon } from '@heroicons/react/solid'
import { ArrowUpIcon, ArrowDownIcon, PencilIcon } from '@heroicons/react/outline'
import { BookOpenIcon } from '@heroicons/react/outline'
import { getIsResourceOwned } from './Resources'

const ResourceItem = ({ resource, currentUser, onClick, onClickResourceEdit }) => {
  const [isReadingMore, setIsReadingMore] = useState(false)
  const isDescriptionLong = resource.description.length >= 80
  const shortenedDescription = resource.description.substring(0, 80) + '...'

  return (
    <>
      <div
        className="h-full flex flex-col group relative rounded-md hover:bg-pink-50 transition-colors duration-150 pb-3 cursor-pointer"
        onClick={onClick}
      >
        <div
          className="relative w-full h-60 bg-gray-200 rounded-md overflow-hidden lg:h-60 bg-cover bg-center"
          style={{
            backgroundImage: resource.coverPhotoUrl ? 'url(' + resource.coverPhotoUrl + ')' : null
          }}
        >
          <div className="flex justify-between px-4 py-3">
            {resource.visibility.includes('For Sale') ? (
              <span className="bg-purple-450 rounded-md text-white px-3 py-2 text-xs">
                Paid Upgrade
                {getIsResourceOwned(resource, currentUser) ? ' · OWNED' : null}
              </span>
            ) : (
              <div />
            )}
            <span className="bg-purple-450 rounded-md text-white px-3 py-1 text-xs flex items-center">
              {'Link' === resource.type && <LinkIcon className="w-5 h-5 mr-1" />}
              {'Article' === resource.type && <PaperClipIcon className="w-5 h-5 mr-1" />}
              {'Course' === resource.type && <BookOpenIcon className="w-5 h-5 mr-1" />}
              {resource.type}
            </span>
          </div>
        </div>
        <div className="flex-1">
          <div className="mt-4 flex justify-between px-4">
            <div>
              <h3 className="text-sm text-purple-450">
                <span aria-hidden="true" className="absolute inset-0" />
                {resource.title}
              </h3>
            </div>
            <p className="text-sm font-medium text-purple-450">
              {new Date(resource.publishedAt).toLocaleDateString()}
            </p>
          </div>
          <div className="flex flex-col px-4">
            <p className="text-sm text-gray-500 mt-3">
              {isDescriptionLong && !isReadingMore ? shortenedDescription : resource.description}
            </p>
          </div>
        </div>
      </div>

      <div>
        {resource.description.length >= 80 && (
          <button
            onClick={() => setIsReadingMore((val) => !val)}
            className="w-full flex items-center justify-center bg-pink-100 hover:bg-pink-200 text-purple-450 text-xs py-2 rounded-md"
          >
            <span className="mr-1">Read {isReadingMore ? 'Less' : 'More'}</span>
            {isReadingMore ? (
              <ArrowUpIcon className="h-3 w-3" />
            ) : (
              <ArrowDownIcon className="h-3 w-3" />
            )}
          </button>
        )}

        {'admin' === currentUser?.role && (
          <button
            onClick={() => onClickResourceEdit(resource)}
            className="w-full flex items-center justify-center bg-gray-200 hover:bg-pink-200 text-purple-450 text-xs py-2 rounded-md"
          >
            <PencilIcon className="w-3 h-3 mr-1" />
            <span>Edit</span>
          </button>
        )}
      </div>
    </>
  )
}

export default ResourceItem
