import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardBannerForm from '../../features/dashboard/DashboardBannerForm'
import { getBannerByType, getBanners, removeBanner } from '../../features/dashboard/dashboardSlice'
import { Banner } from './Banner'
import FormModal from './FormModal'
import PrimaryButton from './PrimaryButton'

const DashboardBanner = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.auth)
  const [bannerModalOpen, setModalBannerOpen] = useState(false)
  const [editingBanner, setEditingBanner] = useState({})
  const dashboardBasicBanner = useSelector((state) => state.dashboardBanner.basicBanner)
  const dashboardCourseOnlyBanner = useSelector((state) => state.dashboardBanner.courseOnlyBanner)
  const dashboardPremiumBanner = useSelector((state) => state.dashboardBanner.premiumBanner)
  const [basicBanner, setBasicBanner] = useState(dashboardBasicBanner)
  const [courseOnlyBanner, setCourseOnlyBanner] = useState(dashboardCourseOnlyBanner)
  const [premiumBanner, setPremiumBanner] = useState(dashboardBasicBanner)

  useEffect(() => {
    if (currentUser.role === 'admin') {
      dispatch(getBanners(null))
    } else if (currentUser.role === 'basic') {
      dispatch(getBannerByType({ type: 'basic' }))
    } else if (currentUser.role === 'course only') {
      dispatch(getBannerByType({ type: 'course only' }))
    } else if (currentUser.role === 'premium' || currentUser.role === 'coach') {
      dispatch(getBannerByType({ type: 'premium' }))
    }
  }, [dispatch, currentUser])

  useEffect(() => {
    setBasicBanner(dashboardBasicBanner)
    setCourseOnlyBanner(dashboardCourseOnlyBanner)
    setPremiumBanner(dashboardPremiumBanner)
  }, [dashboardBasicBanner, dashboardCourseOnlyBanner, dashboardPremiumBanner])

  const createBanner = (type) => {
    setModalBannerOpen(true)
    setEditingBanner({ type })
  }

  const editBanner = (banner) => {
    setModalBannerOpen(true)
    setEditingBanner(banner)
  }

  return (
    <>
      <div className="space-y-3 mt-3">
        {premiumBanner &&
          (currentUser.role === 'premium' ||
            currentUser.role === 'coach' ||
            currentUser.role === 'admin') && (
            <Banner banner={premiumBanner} editBanner={editBanner} />
          )}
        {courseOnlyBanner &&
          (currentUser.role === 'course only' || currentUser.role === 'admin') && (
            <Banner banner={courseOnlyBanner} editBanner={editBanner} />
          )}
        {basicBanner && (currentUser.role === 'basic' || currentUser.role === 'admin') && (
          <Banner banner={basicBanner} editBanner={editBanner} />
        )}

        {!premiumBanner && currentUser.role === 'admin' && (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <PrimaryButton
              type="submit"
              extraClasses="uppercase"
              label="New Banner for Premium Users"
              onClick={() => createBanner('premium')}
            ></PrimaryButton>
          </div>
        )}
        {!courseOnlyBanner && currentUser.role === 'admin' && (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <PrimaryButton
              type="submit"
              extraClasses="uppercase"
              label="New Banner for Course Only Users"
              onClick={() => createBanner('course only')}
            ></PrimaryButton>
          </div>
        )}
        {!basicBanner && currentUser.role === 'admin' && (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <PrimaryButton
              type="submit"
              extraClasses="uppercase"
              label="New Banner for Basic Users"
              onClick={() => createBanner('basic')}
            ></PrimaryButton>
          </div>
        )}
      </div>
      <FormModal
        open={bannerModalOpen}
        setOpen={setModalBannerOpen}
        Form={DashboardBannerForm}
        editingItem={editingBanner}
        setEditingItem={setEditingBanner}
        deleteAction={() => dispatch(removeBanner({ type: editingBanner.type }))}
      />
    </>
  )
}

export default DashboardBanner
