import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

const TablePagination = ({
  page,
  goToPrevPage,
  goToNextPage,
  setItemsPerPage,
  itemsPerPage,
  itemsPerPageLabel = 'Items per page',
  count
}) => {
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div></div>
      <div className="flex-1 flex items-center sm:justify-end">
        <div className="flex mr-10 justify-between items-center">
          <label htmlFor="itemsPerPage" className="block text-sm font-medium text-gray-700 mr-4">
            {itemsPerPageLabel}
          </label>
          <select
            id="itemsPerPage"
            name="itemsPerPage"
            className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
            value={itemsPerPage}
            onChange={(event) => setItemsPerPage(event.target.value)}
          >
            <option>5</option>
            <option>10</option>
            <option>25</option>
          </select>
        </div>
        <button
          onClick={goToPrevPage}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          disabled={1 === page}
        >
          <ChevronLeftIcon className="h-4 w-4" />
          Previous
        </button>
        <button
          onClick={goToNextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          disabled={!(page * itemsPerPage < count)}
        >
          Next
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  )
}

export default TablePagination
