// this function is needed because admins can input unicode characters into normal input fields
// Example: unicode codes for different E's
// "E".charCodeAt(); // 69 regular
// "𝗘".charCodeAt(); // 55349 bold sans
export function matchSubStringIgnoreUnicode(str1, str2) {
  const normalizedStr1 = str1.normalize('NFKC').toLowerCase()
  const normalizedStr2 = str2.normalize('NFKC').toLowerCase()

  return normalizedStr1.includes(normalizedStr2)
}
