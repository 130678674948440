import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'

const initialState = {
  loading: false,
  lists: [],
  labels: [],
  activeCardId: null,
  mentionedUsers: [],
  loadingReset: false
}

// actions

// selectors

// for card modal
export const selectActiveCard = (state) => {
  const { activeCardId, lists } = state.moveTheNeedle
  let activeCard = null
  if (activeCardId) {
    lists.forEach((list) => {
      if (!activeCard) {
        activeCard = list.Cards.find((card) => card.id === activeCardId)
      }
    })
  }
  return activeCard
}

// actions

export const readManyListsAsync = createAsyncThunk(
  'move-the-needle/list/read-many',
  async (data) => {
    const response = await axios.get('/api/move-the-needle/list/read-many', {
      params: { userId: data?.userId }
    })
    return response.data
  }
)

export const upsertListAsync = createAsyncThunk('move-the-needle/list/upsert', async ({ list }) => {
  const response = await axios.post('/api/move-the-needle/list/upsert', list)
  return response.data
})

export const deleteListAsync = createAsyncThunk('move-the-needle/list/delete', async ({ id }) => {
  await axios.delete(`/api/move-the-needle/list/${id}`)
  return { id }
})

export const reorderCardsAsync = createAsyncThunk(
  'move-the-needle/card/reorder',
  async (payload, { dispatch }) => {
    try {
      // manually update positions in a similar way to how the back-end does
      // to prevent having to wait for the response and refresh (that causes janky state change)
      dispatch(reorderCard(payload))
      const response = await axios.post('/api/move-the-needle/card/reorder', payload)
      // TODO: maybe we should fetch the lists after the update
      // to make sure no state drift occurs over time or with errors
      return response.data
    } catch (err) {
      console.error('Error in reorderCardAsync', err)
      // forward error to thunk rejected action
      throw err
    }
  }
)

export const moveCardsAsync = createAsyncThunk(
  'move-the-needle/card/move',
  async (payload, { dispatch }) => {
    dispatch(moveCard(payload))
    const response = await axios.post('/api/move-the-needle/card/move', payload)
    return response.data
  }
)

export const upsertCardAsync = createAsyncThunk('move-the-needle/card/upsert', async ({ card }) => {
  const response = await axios.post('/api/move-the-needle/card/upsert', card)
  return response.data
})

export const deleteCardAsync = createAsyncThunk('move-the-needle/card/delete', async ({ id }) => {
  await axios.delete(`/api/move-the-needle/card/${id}`)
  return { id }
})

export const resetMoveTheNeedleAsync = createAsyncThunk('move-the-needle/reset', async (data) => {
  const response = await axios.put('/api/move-the-needle/reset', {
    id: data?.userId
  })
  return response.data
})

/* Move The Needle Labels */

export const readManyLabelsAsync = createAsyncThunk(
  'move-the-needle/label/read-many',
  async (data) => {
    const response = await axios.get('/api/move-the-needle/label/read-many', {
      params: { userId: data?.userId }
    })
    return response.data
  }
)

export const upsertLabelAsync = createAsyncThunk(
  'move-the-needle/label/upsert',
  async ({ label }) => {
    // upsert label + add it to this card
    const response = await axios.post('/api/move-the-needle/label/upsert', label)
    return response.data
  }
)

export const addLabelToCardAsync = createAsyncThunk(
  'move-the-needle/label/add',
  async ({ LabelId, CardId }) => {
    await axios.post('/api/move-the-needle/label/add', {
      LabelId,
      CardId
    })
    return { LabelId, CardId }
  }
)

export const removeLabelFromCardAsync = createAsyncThunk(
  'move-the-needle/label/remove',
  async ({ LabelId, CardId }) => {
    await axios.post('/api/move-the-needle/label/remove', {
      LabelId,
      CardId
    })
    return { LabelId, CardId }
  }
)

/* Move The Needle Attachments */

export const upsertAttachmentAsync = createAsyncThunk(
  'move-the-needle/attachment/upsert',
  async ({ attachment, CardId }) => {
    // upsert attachment + add it to this card
    const response = await axios.post('/api/move-the-needle/attachment/upsert', {
      ...attachment,
      CardId
    })
    return response.data
  }
)

export const deleteAttachmentAsync = createAsyncThunk(
  'move-the-needle/attachment/delete',
  async ({ id }) => {
    await axios.delete(`/api/move-the-needle/attachment/${id}`)
    return { id }
  }
)

/* Move The Needle Activity */

export const upsertActivityAsync = createAsyncThunk(
  'move-the-needle/activity/upsert',
  async ({ activity, CardId, mentionedUsers }) => {
    const response = await axios.post('/api/move-the-needle/activity/upsert', {
      ...activity,
      CardId,
      mentionedUsers
    })
    return response.data
  }
)

/* Move The Needle CheckList */

export const upsertCheckListAsync = createAsyncThunk(
  'move-the-needle/checkList/upsert',
  async ({ checkList }, { getState }) => {
    const {
      moveTheNeedle: { activeCardId }
    } = getState()
    const response = await axios.post('/api/move-the-needle/checkList/upsert', {
      ...checkList,
      CardId: activeCardId
    })
    return response.data
  }
)

export const deleteCheckListAsync = createAsyncThunk(
  'move-the-needle/checkList/delete',
  async (id, { dispatch }) => {
    await axios.delete('/api/move-the-needle/checkList', { data: { id } })
    // trigger a refresh
    dispatch(readManyListsAsync())
  }
)

export const deleteCheckListItemAsync = createAsyncThunk(
  'move-the-needle/checkList/delete',
  async (id, { dispatch }) => {
    await axios.delete('/api/move-the-needle/checkListItem', { data: { id } })
    // trigger a refresh
    dispatch(readManyListsAsync())
  }
)

export const upsertCheckListItemAsync = createAsyncThunk(
  'move-the-needle/checkListItem/upsert',
  async ({ checkListItem, CheckListId }) => {
    const response = await axios.post('/api/move-the-needle/checkListItem/upsert', {
      ...checkListItem,
      CheckListId
    })
    return response.data
  }
)

export const moveTheNeedleSlice = createSlice({
  name: 'moveTheNeedle',
  initialState,
  reducers: {
    reorderCard: (state, action) => {
      const { ListId, startPosition, endPosition } = action.payload

      const list = state.lists.find((list) => list.id === ListId)
      const targetCard = list.Cards.find((card) => card.position === startPosition)
      // 2 possible scenarios
      // 1. card position decreases (e.g. from 4 to 0)
      //    get all cards at end position and above and increment by 1
      // 2. card position increases (from 0 to 4)
      //    get all cards between start and end position and decrement by 1
      // finally change target card to end position
      if (startPosition > endPosition) {
        list.Cards.forEach((card) => {
          if (card.position >= endPosition && card.position < startPosition) {
            card.position++
          }
        })
      } else {
        list.Cards.forEach((card) => {
          if (card.position > startPosition && card.position <= endPosition) {
            card.position--
          }
        })
      }
      // finally
      targetCard.position = endPosition

      list.Cards.sort((a, b) => a.position - b.position)
    },
    moveCard: (state, action) => {
      const { sourceListId, destListId, positionOnSourceList, positionOnDestList } = action.payload

      const sourceListIndex = state.lists.findIndex((list) => list.id === sourceListId)
      const destListIndex = state.lists.findIndex((list) => list.id === destListId)

      // grab all of the cards in list with id sourceListId with position >= positionOnSourceList and -1 them
      state.lists[sourceListIndex].Cards = state.lists[sourceListIndex].Cards.map((card) => {
        if (card.position > positionOnSourceList) card.position--
        return card
      })
      // grab all of the cards in list with id destListId  with position >= positionOnDestList and +1 them
      state.lists[destListIndex].Cards = state.lists[destListIndex].Cards.map((card) => {
        if (card.position >= positionOnDestList) card.position++
        return card
      })

      const [cardToMove] = state.lists[sourceListIndex].Cards.splice(positionOnSourceList, 1)
      cardToMove.position = positionOnDestList
      cardToMove.ListId = destListId
      state.lists[destListIndex].Cards.splice(positionOnDestList, 0, cardToMove)
    },

    setActiveCardId: (state, action) => {
      state.activeCardId = action.payload.activeCardId
    },
    mentionUser: (state, action) => {
      state.mentionedUsers.push(action.payload.user)
    },
    mentionRemoveUser: (state, action) => {
      state.mentionedUsers = state.mentionedUsers.filter(
        (user) => user.id !== action.payload.UserId
      )
    },
    clearMentionedUsers: (state) => {
      state.mentionedUsers = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetMoveTheNeedleAsync.pending, (state, action) => {
        state.loadingReset = false
      })
      .addCase(resetMoveTheNeedleAsync.fulfilled, (state, action) => {
        state.loadingReset = true
        toast.success('MTN reset successfully')
      })
      .addCase(readManyListsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readManyListsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lists = action.payload.lists
      })
      .addCase(readManyListsAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(deleteListAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteListAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lists = state.lists.filter((list) => list.id !== action.payload.id)
        toast.success('Card removed successfully')
      })
      .addCase(deleteListAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(reorderCardsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(reorderCardsAsync.fulfilled, (state) => {
        state.loading = false
        toast.success('Card moved')
      })
      .addCase(reorderCardsAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(moveCardsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(moveCardsAsync.fulfilled, (state) => {
        state.loading = false
        toast.success('Card moved')
      })
      .addCase(moveCardsAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(deleteCardAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteCardAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lists = state.lists.map((list) => {
          list.Cards = list.Cards.filter((card) => card.id !== action.payload.id)
          // update card positions
          list.Cards.forEach((card, index) => (card.position = index))
          return list
        })
        toast.success('Card removed successfully')
      })
      .addCase(deleteCardAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(upsertCardAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertCardAsync.fulfilled, (state, action) => {
        state.loading = false
        const listIndex = state.lists.findIndex((list) => list.id === action.payload.card.ListId)
        if (action.payload.isNew) {
          // insert
          state.lists[listIndex].Cards.push(action.payload.card)
        } else {
          // update
          state.lists[listIndex].Cards = state.lists[listIndex].Cards?.map((card) =>
            card.id === action.payload.card.id ? action.payload.card : card
          )
        }
        toast.success('Card updated')
      })
      .addCase(upsertCardAsync.rejected, (state) => {
        state.loading = false
      })

      .addCase(upsertListAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertListAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          action.payload.list.Cards = []
          state.lists.push(action.payload.list)
        } else {
          // update
          state.lists = state.lists.map((list) =>
            list.id === action.payload.list.id ? action.payload.list : list
          )
        }
        toast.success('List added')
      })
      .addCase(upsertListAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(readManyLabelsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readManyLabelsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.labels = action.payload.labels
      })
      .addCase(readManyLabelsAsync.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(upsertLabelAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertLabelAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.labels.push(action.payload.label)
        } else {
          // update
          state.labels = state.labels.map((label) =>
            label.id === action.payload.label.id ? action.payload.label : label
          )
        }
        toast.success('Label updated')
      })
      .addCase(upsertLabelAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(addLabelToCardAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(addLabelToCardAsync.fulfilled, (state, action) => {
        state.loading = false

        const label = (state.labels || []).find((label) => label.id === action.payload.LabelId)

        state.lists = state.lists.map((list) => {
          list.Cards.map((card) => {
            if (card.id === action.payload.CardId) {
              card.Labels.push(label)
            }
            return card
          })
          return list
        })
        toast.success('Added label')
      })
      .addCase(addLabelToCardAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(removeLabelFromCardAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(removeLabelFromCardAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lists = state.lists.map((list) => {
          list.Cards.map((card) => {
            if (card.id === action.payload.CardId) {
              card.Labels = card.Labels.filter((label) => label.id !== action.payload.LabelId)
            }
            return card
          })
          return list
        })
        toast.success('Removed label from card')
      })
      .addCase(removeLabelFromCardAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(upsertAttachmentAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertAttachmentAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.lists = state.lists.map((list) => {
            list.Cards = list.Cards.map((card) => {
              if (card.id === action.payload.attachment.CardId) {
                card.Attachments.unshift(action.payload.attachment)
              }
              return card
            })

            return list
          })
        } else {
          // update
          state.lists = state.lists.map((list) => {
            const card = list.Cards.find((card) => card.id === action.payload.CardId)
            if (card) {
              card.Attachments = card.Attachments.map((attachment) => {
                if (attachment.id === action.payload.attachment.id) {
                  return action.payload.attachment
                }
                return attachment
              })
            }

            return list
          })
        }
        toast.success('Attachment added')
      })
      .addCase(upsertAttachmentAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(deleteAttachmentAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteAttachmentAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lists = (state.lists || []).map((list) => {
          list.Cards = (list.Cards || []).map((card) => {
            card.Attachments = (card.Attachments || []).filter(
              (attachment) => attachment.id !== action.payload.id
            )
            return card
          })
          return list
        })
        toast.success('Attachment deleted')
      })
      .addCase(deleteAttachmentAsync.rejected, (state) => {
        state.loading = false
      })
      .addCase(upsertActivityAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertActivityAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.lists = state.lists.map((list) => {
            list.Cards = list.Cards.map((card) => {
              if (card.id === action.payload.activity.CardId) {
                card.Activities.unshift(action.payload.activity)
              }
              return card
            })

            return list
          })
        } else {
          // update
          state.lists = state.lists.map((list) => {
            const card = list.Cards.find((card) => card.id === action.payload.CardId)
            if (card) {
              card.Activities = card.Activities.map((activity) => {
                if (activity.id === action.payload.activity.id) {
                  return action.payload.activity
                }
                return activity
              })
            }

            return list
          })
        }
        toast.success('Comment added')
      })
      .addCase(upsertActivityAsync.rejected, (state) => {
        state.loading = false
      })

      .addCase(upsertCheckListAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertCheckListAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.lists = state.lists.map((list) => {
            list.Cards = list.Cards.map((card) => {
              if (card.id === action.payload.checkList.CardId) {
                card.CheckLists.push(action.payload.checkList)
              }
              return card
            })

            return list
          })
          toast.success('Checklist added')
        } else {
          // update
          state.lists = state.lists.map((list) => {
            const card = list.Cards.find((card) => card.id === action.payload.checkList.CardId)
            if (card) {
              card.CheckLists = card.CheckLists.map((checkList) => {
                if (checkList.id === action.payload.checkList.id) {
                  return action.payload.checkList
                }
                return checkList
              })
            }

            return list
          })
          toast.success('Checklist updated')
        }
      })
      .addCase(upsertCheckListAsync.rejected, (state) => {
        state.loading = false
      })

      .addCase(upsertCheckListItemAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertCheckListItemAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.lists = state.lists.map((list) => {
            list.Cards = list.Cards.map((card) => {
              if (card.id === state.activeCardId) {
                card.CheckLists = card.CheckLists.map((checkList) => {
                  if (checkList.id === action.payload.checkListItem.CheckListId) {
                    checkList.CheckListItems.push(action.payload.checkListItem)
                  }
                  return checkList
                })
              }
              return card
            })

            return list
          })
          toast.success('Check item added')
        } else {
          // update
          state.lists = state.lists.map((list) => {
            list.Cards = list.Cards.map((card) => {
              if (card.id === state.activeCardId) {
                card.CheckLists = card.CheckLists.map((checkList) => {
                  if (checkList.id === action.payload.checkListItem.CheckListId) {
                    checkList.CheckListItems = checkList.CheckListItems.map((checkListItem) => {
                      if (checkListItem.id === action.payload.checkListItem.id) {
                        return action.payload.checkListItem
                      }
                      return checkListItem
                    })
                  }
                  return checkList
                })
              }
              return card
            })

            return list
          })
          toast.success('Check item updated')
        }
      })
      .addCase(upsertCheckListItemAsync.rejected, (state) => {
        state.loading = false
      })
  }
})

export const {
  reorderCard,
  moveCard,
  setActiveCardId,
  mentionUser,
  mentionRemoveUser,
  clearMentionedUsers
} = moveTheNeedleSlice.actions

export default moveTheNeedleSlice.reducer
