import React from 'react'
import { SearchIcon } from '@heroicons/react/solid'

const ResourceSearch = ({ search, setSearch }) => {
  return (
    <div>
      <div className="relative rounded-md shadow-sm mr-4">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className="focus:ring-purple-450 focus:border-purple-450 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search"
        />
      </div>
    </div>
  )
}

export default ResourceSearch
