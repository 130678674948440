import React from 'react'
import { Link } from 'react-router-dom'

const NoAccess = () => {
  return (
    <div className="h-screen py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 text-center flex flex-col h-full justify-center">
        <h1 className="text-2xl font-semibold text-gray-900">
          This Area Is For Mastermind Members Only |{' '}
          <a
            href="https://go.therainmakerchallenge.com/finishline"
            target="_blank"
            rel="noreferrer"
            className="text-purple-450"
          >
            Apply Now
          </a>
        </h1>
        <div className="mt-10 mx-auto flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
          <Link
            to="/"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
          >
            Go back home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NoAccess
