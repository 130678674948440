import { SearchIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'
import { getCompletedLessonsAsync, readManyModulesAsync } from '../course/courseSlice'
import { readManyListsAsync } from '../move-the-needle/moveTheNeedleSlice'
import { countProductsAsync } from '../power-profit-tool/powerProfitToolSlice'
import { readManyResourcesAsync } from '../resource/resourceSlice'
import * as moment from 'moment'
import DashboardBanner from '../../components/general/DashboardBanner'
import ErrorBoundary from '../../components/general/ErrorBoundary'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { productsTotal } = useSelector((state) => state.powerProfitTool)
  const { lists } = useSelector((state) => state.moveTheNeedle)
  const { modules, completedLessons } = useSelector((state) => state.course)
  const { currentUser } = useSelector((state) => state.auth)

  const [courseProgress, setCourseProgress] = useState(0)

  useEffect(() => {
    let totalLessons = 0
    let totalLessonsCompleted = 0

    modules.forEach((module) =>
      module.Lessons.forEach((lesson) => {
        ++totalLessons

        if (completedLessons.find((cl) => cl.id === lesson.id)) {
          ++totalLessonsCompleted
        }
      })
    )

    return 0 === totalLessonsCompleted
      ? setCourseProgress(0)
      : setCourseProgress(parseInt((totalLessonsCompleted / totalLessons) * 100))
  }, [modules, completedLessons])

  useEffect(() => {
    dispatch(readManyListsAsync({}))
    dispatch(readManyModulesAsync({}))
    dispatch(getCompletedLessonsAsync())
    dispatch(countProductsAsync())
    dispatch(readManyResourcesAsync({ itemsPerPage: 15, filters: {} }))
  }, [dispatch])

  // resources
  const { resources } = useSelector((state) => state.resource)

  const [donutValues, setDonutValues] = useState({
    progress: 0,
    hold: 0,
    completed: 0
  })
  const segmentOffset = 25
  const segmentSpacing = 1

  useEffect(() => {
    if (!lists) return
    const MTNDataReduced = lists?.reduce((acc, board) => {
      switch (board.name) {
        case 'NOT STARTED':
        case 'IN PROGRESS':
        case 'COMPLETED':
        case 'ON HOLD':
          if (!acc['total']) {
            acc['total'] = board.Cards.length
          } else {
            acc['total'] += board.Cards.length
          }
          acc[board.name] = board.Cards.length
          break
        default:
          break
      }

      return acc
    }, {})

    if (0 === MTNDataReduced.total) {
      setDonutValues({
        notStarted: 0,
        progress: 0,
        hold: 0,
        completed: 0
      })
    } else {
      setDonutValues({
        notStarted: MTNDataReduced['NOT STARTED'] / (MTNDataReduced.total / 100),
        progress: MTNDataReduced['IN PROGRESS'] / (MTNDataReduced.total / 100),
        hold: MTNDataReduced['ON HOLD'] / (MTNDataReduced.total / 100),
        completed: MTNDataReduced['COMPLETED'] / (MTNDataReduced.total / 100)
      })
    }
  }, [lists])

  return (
    <div className="py-6 mb-12" style={{ background: '#e5e5e5' }}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Your Rainmakers Dashboard</h1>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <div className="py-4">
          <img src="/images/dashboard-banner.png" alt="dashboard banner" />
          <DashboardBanner />
          <div className="mt-4 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
            <div className="flex-1">
              <div className="flex flex-col bg-white px-4 py-4 shadow-md rounded-lg">
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="uppercase text-xs font-semibold inline-block mr-2">
                        Course Progress:
                      </span>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-450 bg-pink-100">
                        {courseProgress}%
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-xs font-semibold inline-block text-purple-450">
                        <Link to="/course">Continue in Course</Link>
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200 mt-4">
                    <div
                      style={{ width: `${courseProgress}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-450"
                    ></div>
                  </div>
                </div>
              </div>
              <ErrorBoundary fallback={CommunitySearchErrorUI}>
                <CommunitySearch />
              </ErrorBoundary>
            </div>
            <div className="flex-1">
              <div className="flex flex-col bg-white px-4 py-4 shadow-md rounded-lg">
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="uppercase text-xs font-semibold inline-block mr-2">
                        Total Products:
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-xs font-semibold inline-block text-purple-450">
                        <Link to="/profit-calculator">View Power Profit Tool</Link>
                      </span>
                    </div>
                  </div>
                  <div className="text-2xl">{productsTotal}</div>
                </div>
              </div>
              <div className=" bg-white rounded-lg mt-6 shadow h-96 px-4">
                <div className="flex justify-between items-center py-3 h-20">
                  <h2>Recent Resources</h2>

                  <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-purple-450">
                      <Link to="/resources">View All Resources</Link>
                    </span>
                  </div>
                </div>
                <div className="mt-1 h-72 overflow-auto">
                  <ul>
                    {resources?.map((resource) => (
                      <li key={resource.id} className="border-t py-2">
                        <Link
                          to={`/resources?id=${resource.id}`}
                          onClick={() => {
                            // scroll to top
                            document.getElementById('main').scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex">
                              {resource.coverPhotoUrl ? (
                                <img
                                  className="inline-block h-9 w-9 rounded-full"
                                  src={resource.coverPhotoUrl}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="inline-block h-9 w-9 rounded-full"
                                  src="https://storage.googleapis.com/rainmakers-academy-cms-staging-bucket/1637320669636.png"
                                  alt=""
                                />
                              )}

                              <div className="ml-3">
                                <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                  {resource.title}
                                </p>
                                <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                  {resource?.ResourceCategory?.name ?? '-'}
                                </p>
                              </div>
                            </div>

                            <div className="text-xs text-gray-400">
                              {moment(resource.createdAt).fromNow()}
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {currentUser?.role !== 'basic' && (
              <div className="flex-1">
                <div className="flex flex-col bg-white px-4 py-4 shadow-md rounded-t-lg">
                  <div className="relative pt-1">
                    <div className="flex mb-2 items-center justify-between">
                      <div>
                        <span className="uppercase text-xs font-semibold inline-block mr-2">
                          Move The Needle:
                        </span>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-450 bg-pink-100">
                          {Math.round(donutValues.completed || 0)}%
                        </span>
                      </div>
                      <div className="text-right">
                        <span className="text-xs font-semibold inline-block text-purple-450">
                          <Link to="/move-the-needle">View Progress</Link>
                        </span>
                      </div>
                    </div>
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200 mt-4">
                      <div
                        style={{
                          width: `${Math.round(donutValues.completed || 0)}%`
                        }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-450"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-b-lg shadow px-4 pb-4">
                  <div className="border-t"></div>
                  <figure>
                    <div className="figure-content">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 42 42"
                        className="donut"
                        role="img"
                      >
                        {!donutValues.progress && !donutValues.hold && !donutValues.completed ? (
                          <>
                            <circle
                              className="donut-hole"
                              cx="21"
                              cy="21"
                              r="15.91549430918954"
                              fill="#fff"
                              role="presentation"
                            ></circle>
                            <circle
                              className="donut-ring"
                              cx="21"
                              cy="21"
                              r="15.91549430918954"
                              fill="transparent"
                              stroke="#d2d3d4"
                              strokeWidth="2"
                              role="presentation"
                            ></circle>
                          </>
                        ) : (
                          <>
                            {donutValues.progress && (
                              <circle
                                className="donut-segment"
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                stroke="#3e73eb"
                                strokeWidth="2"
                                strokeDasharray={`${donutValues.progress - segmentSpacing} ${
                                  100 - donutValues.progress + segmentSpacing
                                }`}
                                strokeDashoffset={0 + segmentOffset}
                                aria-labelledby="donut-segment-1-title donut-segment-1-desc"
                              ></circle>
                            )}

                            {donutValues.hold && (
                              <circle
                                className="donut-segment"
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                stroke="#489f87"
                                strokeWidth="2"
                                strokeDasharray={`${donutValues.hold - segmentSpacing} ${
                                  100 - donutValues.hold + segmentSpacing
                                }`}
                                strokeDashoffset={`${100 - donutValues.progress + segmentOffset}`}
                              ></circle>
                            )}

                            {donutValues.completed && (
                              <circle
                                className="donut-segment"
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                stroke="#b757f6"
                                strokeWidth="2"
                                strokeDasharray={`${donutValues.completed - segmentSpacing} ${
                                  100 - donutValues.completed + segmentSpacing
                                }`}
                                strokeDashoffset={`${
                                  100 - donutValues.progress - donutValues.hold + segmentOffset
                                }`}
                              ></circle>
                            )}

                            {donutValues.notStarted && (
                              <circle
                                className="donut-segment"
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                stroke="#EBEBEB"
                                strokeWidth="2"
                                strokeDasharray={`${donutValues.notStarted - segmentSpacing} ${
                                  100 - donutValues.notStarted + segmentSpacing
                                }`}
                                strokeDashoffset={`${
                                  100 -
                                  donutValues.progress -
                                  donutValues.hold -
                                  donutValues.completed +
                                  segmentOffset
                                }`}
                              ></circle>
                            )}
                          </>
                        )}
                      </svg>
                    </div>
                  </figure>
                  <div className="flex justify-between text-center">
                    <div className="p-4 border-t border-r flex flex-col flex-1">
                      <span>{Math.round(donutValues.progress || 0)}%</span>
                      <span className="uppercase text-gray-500" style={{ fontSize: 10 }}>
                        in progress
                      </span>
                    </div>
                    <div className="p-4 border-t border-r flex flex-col flex-1">
                      <span>{Math.round(donutValues.hold || 0)}%</span>
                      <span className="uppercase text-gray-500" style={{ fontSize: 10 }}>
                        on hold
                      </span>
                    </div>
                    <div className="p-4 border-t flex flex-col flex-1 ">
                      <span>{Math.round(donutValues.completed || 0)}%</span>
                      <span className="uppercase text-gray-500" style={{ fontSize: 10 }}>
                        completed
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* /End replace */}
      </div>
    </div>
  )
}

function getUserInitials(fullName) {
  return (
    fullName
      .split(' ')
      .map((name) => name[0])
      // get first two initials
      .slice(0, 2)
      .join('')
      .toUpperCase()
  )
}

const CommunitySearch = () => {
  const { users, loading, search, setSearch, fetchNextPage } = useCommunityUsers()

  return (
    <div className=" bg-white rounded-lg mt-6 shadow px-4 py-4 h-96">
      <div className="flex justify-between items-center">
        <h2 className="mr-5">Community Search</h2>
        <div className="flex-1">
          <div>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="search"
                name="search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                className="focus:ring-purple-450 focus:border-purple-450 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="City or State"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 h-72 overflow-auto">
        <ul>
          {!loading && !users?.length && (
            <p className="p-2">No Rainmakers found. Try searching a neighboring city!</p>
          )}
          <InfiniteScroll
            dataLength={users.length}
            next={fetchNextPage}
            hasMore={true}
            loader={null}
            endMessage={null}
            // needed for container to know when to load next
            // set to 288 because of parent div height (h-72)
            height={288}
          >
            {users?.map((user, i) => {
              return <CommunityUser key={i} user={user} />
            })}
          </InfiniteScroll>
        </ul>
      </div>
    </div>
  )
}

const useCommunityUsers = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState()
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  // handles page change
  useEffect(() => {
    async function fetchUsers() {
      const params = { page, usersPerPage: 15, filters: { role: ['basic', 'premium'] } }
      if (search) params.filters.cityOrState = search

      const { data } = await axios.get('/api/user/read-many', { params })

      const result = {
        users: data.users,
        premiumCount: data.premiumCount,
        basicCount: data.basicCount,
        totalCount: data.totalCount
      }
      setUsers((users) => [...users, ...result.users])
      setLoading(false)
      setHasMore(result.totalCount > users.length)
    }
    fetchUsers()
    // should not depend on users.length to prevent infinite loop
    // should not depend on search because when search is changed the user list should be reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  // handles search change
  useEffect(() => {
    async function fetchUsers() {
      const params = { page, usersPerPage: 15, filters: { role: ['basic', 'premium'] } }
      if (search) params.filters.cityOrState = search

      const { data } = await axios.get('/api/user/read-many', { params })

      const result = {
        users: data.users,
        premiumCount: data.premiumCount,
        basicCount: data.basicCount,
        totalCount: data.totalCount
      }
      // reset page to 1 and overwrite users
      setPage(1)
      setUsers(result.users)
      setHasMore(result.totalCount > users.length)
    }
    fetchUsers()
    // should not contain other deps because this should only run when search is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const fetchNextPage = () => {
    setPage(page + 1)
  }

  return { users, loading, search, setSearch, hasMore, fetchNextPage }
}

const CommunitySearchErrorUI = () => {
  return (
    <h1 className="w-full h-96 bg-white text-center py-12 rounded-lg mt-6 shadow text-xl">
      Oops! Something went wrong.
    </h1>
  )
}

const CommunityUser = ({ user }) => {
  return (
    <li key={user.id} className="border-t py-2">
      <div className="flex items-center">
        <div>
          {user.photoUrl ? (
            <img className="inline-block h-9 w-9 rounded-full" src={user.photoUrl} alt="" />
          ) : (
            <span className="inline-flex items-center justify-center h-9 w-9 rounded-full bg-gray-500">
              <span className="text-xs font-medium leading-none text-white">
                {getUserInitials(user.fullName)}
              </span>
            </span>
          )}
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 capitalize">
            {user.fullName}
          </p>
          {user.city && user.state ? (
            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
              {user.city}, {user.state}
            </p>
          ) : (
            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
              United States
            </p>
          )}
        </div>
      </div>
    </li>
  )
}

export default Dashboard
