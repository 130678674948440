import React, { useState, useEffect } from 'react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FormModal from '../../components/general/FormModal'
import {
  deleteResourceAsync,
  deleteResourceCategoryAsync,
  readManyResourceCategoriesAsync,
  readManyResourcesAsync,
  readResourceByIdAsync,
  upsertResourceAsync
} from './resourceSlice'
import ResourceForm from './ResourceForm'
import ResourceCategoryForm from './ResourceCategoryForm'
import ResourcesFilters from './ResourcesFilters'
import ResourceItem from './ResourceItem'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import ConfirmPurchaseForm from './ConfirmPurchaseForm'
import PrimaryButton from '../../components/general/PrimaryButton'
import { useCourseModules } from '../course/courseSlice'
import MarkdownPreview from '../../components/general/MarkdownPreview'

const Resources = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.auth)
  const { resources } = useSelector((state) => state.resource)

  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search)
  const resourceIdFromQuery = locationSearch.get('id')
  // can also have query string with category ids, e.g. ?categories=1&categories=2
  const categoryIdsFromQuery = locationSearch.getAll('categories').map(Number)
  const typeFromQuery = locationSearch.get('type') || 'all'

  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [viewingResource, setViewingResource] = useState(null)
  const [filters, setFilters] = useState({ type: typeFromQuery, categories: categoryIdsFromQuery })
  const [search, setSearch] = useState('')
  const { modules, refreshModules } = useCourseModules()

  useEffect(() => {
    // initial load is either with the resource id from the query string or all resources
    // and then if the user changes the filters or search we load all resources with the new filters
    if (isInitialLoad) {
      dispatch(readManyResourceCategoriesAsync())
      if (resourceIdFromQuery) dispatch(readResourceByIdAsync(resourceIdFromQuery))
      else dispatch(readManyResourcesAsync({ filters, search, itemsPerPage: 1000 }))
      setIsInitialLoad(false)
    } else dispatch(readManyResourcesAsync({ filters, search, itemsPerPage: 1000 }))

    // purposefully exclude isInitialLoad from the dependency array
    // otherwise the readResourceByIdAsync action will be overriden by the readManyResourcesAsync action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resourceIdFromQuery, filters, search])

  // modal
  const [resourceCategoryModalOpen, setResourceCategoryModalOpen] = useState(false)
  const [resourceModalOpen, setResourceModalOpen] = useState(false)
  const [editingResource, setEditingResource] = useState({})
  const [editingResourceCategory, setEditingResourceCategory] = useState({})
  const [confirmPurchaseModalOpen, setConfirmPurchaseModalOpen] = useState(false)

  const onClickForSaleResource = (resource) => {
    const isResourceOwned = getIsResourceOwned(resource, currentUser)
    const isLink = resource.type === 'Link'
    const isCourse = resource.type === 'Course'
    const isArticle = resource.type === 'Article'
    const isVideo = resource.type === 'Video'

    if (isResourceOwned && isLink) return window.open(resource.url, '_blank')
    if (isResourceOwned && isCourse) {
      // TODO: load modules on page load if not in state (redirect won't work if you haven't visited the course page yet)
      // TODO: reload modules after purchase (redirect won't work if you just purchased the course)
      const url = getRedirectLinkForCourseResource(resource, modules)
      return url ? window.open(url, '_blank') : null
    }
    if (isResourceOwned && (isArticle || isVideo)) return setViewingResource(resource)

    if (!isResourceOwned) {
      if (resource.purchaseUrl) return window.open(resource.purchaseUrl, '_blank')

      setEditingResource(resource)
      setConfirmPurchaseModalOpen(true)
    }
  }

  const onChangeFilters = (newFilters) => {
    const newCategoryIds = newFilters.categories
    const newType = newFilters.type
    // set the query string
    const newSearch = new URLSearchParams()
    newCategoryIds.forEach((id) => newSearch.append('categories', id))
    newSearch.set('type', newType)
    // update the url
    window.history.pushState({}, '', `${location.pathname}?${newSearch}`)
    setFilters(newFilters)
  }

  const onPaymentSuccess = async () => {
    await dispatch(readManyResourcesAsync({ filters, search, itemsPerPage: 1000 }))
    await refreshModules()
  }

  const onClickResource = (resource) => {
    if (resource.visibility === 'For Sale') onClickForSaleResource(resource)
    else if (resource.type === 'Link') window.open(resource.url, '_blank')
    else if (resource.type === 'Article' || resource.type === 'Video') setViewingResource(resource)
  }

  const onClickResourceEdit = (resource) => {
    setEditingResource(resource)
    setResourceModalOpen(true)
  }

  return (
    <>
      <div className="h-screen">
        <div className="flex flex-col sm:flex-row">
          <div>
            <ResourcesFilters
              setResourceCategoryModalOpen={setResourceCategoryModalOpen}
              setEditingResourceCategory={setEditingResourceCategory}
              viewingResource={viewingResource}
              filters={filters}
              onChangeFilters={onChangeFilters}
              search={search}
              setSearch={setSearch}
            />
            <div className="pl-4">
              <PrimaryButton
                label="Recommend A Resource/ Service Here"
                onClick={() => window.open('https://wkf.ms/3K6942i', '_blank').focus()}
              />
            </div>
          </div>
          <div className="flex-1 w-full px-4 py-6">
            {viewingResource ? (
              <div>
                <button
                  className="flex px-4 ml-4 items-center justify-center bg-gray-200 hover:bg-pink-200 text-purple-450 text-xs py-2 rounded-md"
                  onClick={() => setViewingResource(null)}
                >
                  <ArrowLeftIcon className="h-5 w-5 mr-2" />
                  back to resources
                </button>

                <div className="px-5" data-color-mode="light">
                  <h1 className="text-2xl text-purple-450 my-6">{viewingResource.title}</h1>
                  <MarkdownPreview markdown={viewingResource.content} />
                </div>
              </div>
            ) : (
              <>
                <div className="">
                  <h1 className="text-2xl font-semibold text-gray-900 flex items-center">
                    Resources
                    {'admin' === currentUser?.role && (
                      <button
                        onClick={() => {
                          setEditingResource(null)
                          setResourceModalOpen(true)
                        }}
                        className="px-4 ml-4 flex items-center justify-center bg-gray-200 hover:bg-pink-200 text-purple-450 text-xs py-2 rounded-md"
                      >
                        <PlusCircleIcon className="w-3 h-3 mr-1" />
                        <span>Add New Resource</span>
                      </button>
                    )}
                  </h1>
                </div>
                <div className="bg-white">
                  <div className="">
                    <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                      {!resources?.length ? (
                        <div>No resources found.</div>
                      ) : (
                        resources.map((resource) => (
                          <div key={resource.id} className="border flex flex-col justify-between">
                            {resource.visibility === 'For Sale' ||
                            resource.type === 'Link' ||
                            resource.type === 'Article' ||
                            resource.type === 'Video' ? (
                              <ResourceItem
                                resource={resource}
                                currentUser={currentUser}
                                onClick={() => onClickResource(resource)}
                                onClickResourceEdit={() => onClickResourceEdit(resource)}
                              />
                            ) : null}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <FormModal
        open={resourceModalOpen}
        setOpen={setResourceModalOpen}
        Form={ResourceForm}
        onSubmit={async (resource) => {
          await dispatch(upsertResourceAsync({ resource }))
          await dispatch(readManyResourcesAsync({ filters, search, itemsPerPage: 1000 }))
        }}
        editingItem={editingResource}
        setEditingItem={setEditingResource}
        deleteAction={async () => {
          await dispatch(deleteResourceAsync({ resource: editingResource }))
        }}
      />

      <FormModal
        open={resourceCategoryModalOpen}
        setOpen={setResourceCategoryModalOpen}
        Form={ResourceCategoryForm}
        editingItem={editingResourceCategory}
        setEditingItem={setEditingResourceCategory}
        deleteAction={async () => {
          await dispatch(deleteResourceCategoryAsync({ category: editingResourceCategory }))
        }}
      />

      <FormModal
        open={confirmPurchaseModalOpen}
        setOpen={setConfirmPurchaseModalOpen}
        Form={ConfirmPurchaseForm}
        onPaymentSuccess={onPaymentSuccess}
        editingItem={editingResource}
        setEditingItem={setEditingResource}
        deleteAction={async () => {}}
      />
    </>
  )
}

export function getIsResourceOwned(resource, currentUser) {
  const isAdmin = currentUser?.role === 'admin'
  // user could have bought the resource on the site or through an external link which updated their tags
  const userOwnsResource = currentUser?.Resources.map((r) => r.id).includes(resource.id)
  const userHasResourceTag = resource.Tags.some((tag) =>
    currentUser?.Tags.map((t) => t.id).includes(tag.id)
  )
  return isAdmin || userOwnsResource || userHasResourceTag
}
function getRedirectLinkForCourseResource(resource, modules) {
  // get the first lesson of the first modules that matches the resource tags
  const resourceTagIds = resource.Tags.map((tag) => tag.id)
  const module = modules.find((module) =>
    module.Tags.some((tag) => resourceTagIds.includes(tag.id))
  )
  if (!module) return

  const lesson = module.Lessons[0]
  if (!lesson) return

  return `/course/${module.id}/${lesson.id}`
}

export default Resources
