import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  MenuAlt2Icon,
  XIcon,
  DesktopComputerIcon,
  ClipboardListIcon,
  ViewListIcon,
  CurrencyDollarIcon,
  TemplateIcon,
  ClipboardCheckIcon,
  SearchIcon,
  PlusCircleIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  TagIcon
} from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { logoutAsync } from '../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import SwitchRoleSelect from './general/SwitchRoleSelect'
import Avatar from './general/Avatar'
import FacebookIcon from '@material-ui/icons/Facebook'
import {
  ADMIN_ROLE,
  ALL_ROLES,
  BASIC_ROLE,
  COURSE_ONLY_ROLE,
  COACH_ROLE,
  PREMIUM_ROLE
} from '../constants'

const userNavigation = [{ name: 'Profile', href: '/profile' }]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DashboardTemplate = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [navigation, setNavigation] = useState([
    {
      name: 'Dashboard',
      href: '/',
      icon: DesktopComputerIcon,
      current: true,
      requiredRoles: ALL_ROLES
    },
    {
      name: 'Courses',
      href: '/course',
      icon: ClipboardListIcon,
      current: false,
      requiredRoles: ALL_ROLES,
      onClick: () => {
        const isAlreadyOnCoursePage = history.location.pathname.includes('/course')
        const isOnTabletOrMobileSize = window.innerWidth < 1024
        if (isAlreadyOnCoursePage && isOnTabletOrMobileSize) {
          document.getElementById('course-modules').scrollIntoView({ behavior: 'smooth' })
        }
        history.push('/course')
        setSidebarOpen(false)
      }
    },
    {
      name: 'Resources',
      href: '/resources',
      icon: ViewListIcon,
      current: false,
      requiredRoles: ALL_ROLES
    },
    {
      name: 'Power Profit Tool',
      href: '/profit-calculator',
      icon: CurrencyDollarIcon,
      current: false,
      requiredRoles: ALL_ROLES
    },
    {
      name: 'Move The Needle',
      href: '/move-the-needle',
      icon: TemplateIcon,
      current: false,
      requiredRoles: [PREMIUM_ROLE, COACH_ROLE]
    },
    {
      name: 'Master Template',
      href: '/move-the-needle',
      icon: TemplateIcon,
      current: false,
      requiredRoles: [ADMIN_ROLE]
    },
    {
      name: 'Product Qualifier',
      href: '/product-qualifier',
      icon: ClipboardCheckIcon,
      current: false,
      requiredRoles: [COURSE_ONLY_ROLE, PREMIUM_ROLE, ADMIN_ROLE, COACH_ROLE]
    },
    {
      name: 'User Dashboard',
      href: '/users',
      icon: UserIcon,
      current: false,
      requiredRoles: [ADMIN_ROLE, COACH_ROLE]
    },
    {
      name: 'Tag Management',
      href: '/tag-management',
      icon: TagIcon,
      current: false,
      requiredRoles: [ADMIN_ROLE]
    }
  ])

  const { currentUser } = useSelector((state) => state.auth)
  const isBasicRole = currentUser?.role === BASIC_ROLE
  const isCourseOnlyRole = currentUser?.role === COURSE_ONLY_ROLE
  const isPremiumRole = currentUser?.role === PREMIUM_ROLE

  useEffect(() => {
    const newNavigation = navigation.map((nav) => {
      const isDashboardNav = nav.href === '/'
      const isOnDashboard = history.location.pathname === '/'
      const isOnCurrentNavPath = history.location.pathname.includes(nav.href)
      const isCurrentNavActive =
        (isOnDashboard && isDashboardNav) || (isOnCurrentNavPath && !isDashboardNav)
      if (isCurrentNavActive) nav.current = true
      else nav.current = false
      return nav
    })

    setNavigation(newNavigation)
    // inluding navigation in the dependency array would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  const rainmakersFacebookGroupLink =
    currentUser?.role === PREMIUM_ROLE
      ? 'https://www.facebook.com/groups/rainmakersvip'
      : 'https://www.facebook.com/groups/rainmakerchallenge'

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 " onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-2 bg-purple-450 py-2">
                <img
                  className="h-28 w-auto mx-auto"
                  src="/images/rainmaker-family.png"
                  alt="Rainmakers Logo"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="flex flex-col justify-between h-full">
                  <div className="px-2 space-y-1">
                    {navigation.map((item, idx) => (
                      <NavigationItem
                        key={idx}
                        item={item}
                        currentUser={currentUser}
                        onNavigate={() => setSidebarOpen(false)}
                      />
                    ))}
                    {(isBasicRole || isCourseOnlyRole) && (
                      <a
                        href={
                          isBasicRole
                            ? 'https://therainmakerchallenge.com/finishline'
                            : 'https://rainmakerfamily.com/mastermind/?el=courseonly'
                        }
                        className="text-base text-gray-500 hover:bg-gray-100 group flex items-center px-2 py-2 rounded-md"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UserIcon className="mr-4 flex-shrink-0 h-6 w-6" />
                        Apply To Upgrade
                      </a>
                    )}
                    <a
                      href={rainmakersFacebookGroupLink}
                      className="text-base text-gray-500 hover:bg-gray-100 group flex items-center px-2 py-2 rounded-md"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon className="mr-4 flex-shrink-0 h-6 w-6" />
                      Facebook Group
                    </a>
                    {!isBasicRole && !isCourseOnlyRole && (
                      <a
                        href="https://searchrainmakers.com"
                        target="_blank"
                        rel="noreferrer"
                        className="w-full text-base text-gray-500 hover:bg-gray-100 group flex items-center px-2 py-2 rounded-md"
                      >
                        <SearchIcon className="mr-4 flex-shrink-0 h-6 w-6" />
                        SearchRainmakers.com
                      </a>
                    )}
                    {isPremiumRole && (
                      <>
                        <a
                          href="https://rainmakerupgrades.com"
                          className="text-base text-gray-500 hover:bg-gray-100 group flex items-center px-2 py-2 rounded-md"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PlusCircleIcon className="mr-4 flex-shrink-0 h-6 w-6" />
                          RainmakerUpgrades.com
                        </a>
                      </>
                    )}
                  </div>

                  <div>
                    <a
                      href="http://rainmakerfamilysupport.com/"
                      target="_blank"
                      rel="noreferrer"
                      className={`${
                        location.pathname.includes('/profile')
                          ? 'text-purple-450'
                          : 'text-gray-500 hover:bg-gray-100'
                      } text-base group flex items-center px-2 py-2 rounded-md`}
                    >
                      <QuestionMarkCircleIcon className="mr-4 flex-shrink-0 h-6 w-6 " />
                      Support
                    </a>
                    <Link
                      to="/profile"
                      className={`${
                        location.pathname.includes('/profile')
                          ? 'text-purple-450'
                          : 'text-gray-500 hover:bg-gray-100'
                      } text-base group flex items-center px-2 py-2 rounded-md`}
                    >
                      <CogIcon className="mr-4 flex-shrink-0 h-6 w-6 " />
                      Settings
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-purple-450 shadow">
          <button
            type="button"
            className="px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-450"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6 " aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex items-center">
              <img
                className="h-28 w-auto"
                src="/images/rainmaker-family.png"
                alt="Rainmakers Logo"
              />
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs  flex items-center text-sm rounded-full focus:outline-none">
                    <span className="sr-only">Open user menu</span>
                    <Avatar user={currentUser} />
                    <span className="ml-3 text-white">{currentUser?.fullName}</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'w-full text-left block px-4 py-2 text-sm text-gray-700'
                          )}
                          onClick={() => dispatch(logoutAsync())}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                    {'admin' === currentUser?.role || currentUser?.isAdmin ? (
                      <SwitchRoleSelect currentUser={currentUser} />
                    ) : null}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main id="main" className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="h-full">{children}</div>
        </main>
      </div>
    </div>
  )
}

const NavigationItem = ({ item, currentUser, onNavigate }) => {
  const { requiredRoles, href, name, icon: Icon, current, onClick } = item
  const isPublicNavItem = requiredRoles.includes('public')
  const userHasAccessToArea = currentUser && requiredRoles.includes(currentUser?.role)

  const className = classNames(
    current ? 'text-purple-450' : 'text-gray-500 hover:bg-gray-100',
    'text-base group flex items-center px-2 py-2 rounded-md cursor-pointer w-full'
  )

  if (!isPublicNavItem && !userHasAccessToArea) return null

  if (!onClick)
    return (
      <Link to={href} role="link" className={className} onClick={onNavigate}>
        <Icon className="mr-4 flex-shrink-0 h-6 w-6" />
        {name}
      </Link>
    )

  return (
    <button role="link" className={className} onClick={onClick}>
      <Icon className="mr-4 flex-shrink-0 h-6 w-6" />
      {name}
    </button>
  )
}

export default DashboardTemplate
