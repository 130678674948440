import React from 'react'
import { useSelector } from 'react-redux'
import PrimaryButton from './PrimaryButton'
import MarkdownPreview from './MarkdownPreview'

export const Banner = ({ banner, editBanner }) => {
  const { currentUser } = useSelector((state) => state.auth)
  return (
    <div className="flex flex-col bg-white px-4 py-4 shadow-md rounded-lg">
      <div className="relative pt-1">
        {currentUser.role === 'admin' && (
          <div className="z-5 absolute w-1/2 lg:w-2/12 right-0">
            <PrimaryButton
              extraClasses="text-xs font-semibold"
              label={'Edit ' + capitalizeFirstLetterOfEveryWord(banner.type)}
              onClick={() => editBanner(banner)}
            />
          </div>
        )}
        <div
          className={`px-4 flex mb-2 flex-col gap-8 lg:gap-0 lg:flex-row items-center justify-between ${
            currentUser.role === 'admin' ? 'mt-14' : ''
          }`}
        >
          <div className={`${banner.video_url ? 'lg:w-4/5' : 'w-full text-center'} flex flex-col`}>
            <h1 className="mb-5 font-bold text-4xl lg:text-5xl">{banner.title}</h1>
            <div
              className={`px-4 w-full flex ${
                !banner.video_url ? 'items-center justify-center' : 'items-start'
              } flex-col text-justify`}
            >
              <div className={`mb-10 ${!banner.video_url ? 'lg:w-4/5' : ''} `}>
                <MarkdownPreview markdown={banner.content} />
              </div>
              <a
                href={banner.button_url}
                target="_blank"
                rel="noreferrer"
                className="flex justify-center py-2 px-4 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
              >
                {banner.button_text}
              </a>
            </div>
          </div>
          {banner.video_url !== '' && (
            <div
              className="w-full relative flex flex-col items-center justify-center"
              style={{ paddingTop: '28%' }}
            >
              <iframe
                title={banner.video_url}
                className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
                src={banner.video_url}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function capitalizeFirstLetterOfEveryWord(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
