import React, { useState, useEffect } from 'react'
import PrimaryButton from '../../components/general/PrimaryButton'
import ProductQualifierQuestion, { LearnMoreButton } from './ProductQualifierQuestion'
import VideoModal from '../../components/general/modal/VideoModal'
import {
  PQ_QUESTIONS,
  saveProductQualifierResultsAsync,
  sendProductQualifierResultsAsync,
  updateAnswers
} from './productQualifierSlice'
import { useDispatch, useSelector } from 'react-redux'
import { InlineLink } from '../power-profit-tool/PowerProfitTool'

const isBadResult = (result) => result <= 7
const isGoodResult = (result) => result === 8 || result === 9
const isGreatResult = (result) => result >= 10

const ProductQualifier = () => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({ volume: 0 })
  const [result, setResult] = useState(0)
  const [resultEmojis, setResultEmojis] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const currentUser = useSelector((state) => state.auth.currentUser)
  const isCourseOnly = currentUser?.role === 'course only'

  const feedbackVideoId = isGoodResult(result)
    ? '720873163'
    : isGreatResult(result)
    ? '720872716'
    : '720870577' // default to bad result

  useEffect(() => {
    let total = 0
    for (const formValue in formValues) {
      if ('volume' === formValue) {
        if (formValues[formValue] > 4000 && formValues[formValue] < 20000) {
          total++
        }
      } else if ('1' === formValues[formValue]) {
        total++
      }
    }
    setResult(total)
    let res
    if (isBadResult(result)) res = '👎👎'
    if (isGoodResult(result)) res = '👍'
    if (isGreatResult(result)) res = '👍👍'
    setResultEmojis(res)
    dispatch(updateAnswers({ result: res, score: result, answers: formValues }))
  }, [dispatch, formValues, result])

  const onChange = (event) => {
    const { name, value } = event.target
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  // "learn more" videos
  const [video, setVideo] = useState(null)

  // copy email
  const [copyEmail, setCopyEmail] = useState('')
  const [keyword, setKeywords] = useState('')
  const { emailDefaults } = useSelector((state) => state.productQ)
  const [emailSent, setEmailSent] = useState(false)

  const isUsingKeyword = formValues.keyword === '1'

  const handleVolumeChange = (event) => {
    setFormValues((prevState) => ({
      ...prevState,
      volume: event.target.value
    }))
  }

  return (
    <div className="h-screen py-6">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold ">Product Qualifier</h1>
        {isCourseOnly && (
          <>
            <h2 className="mt-4 text-lg font-semibold ">Looking to Upgrade?</h2>
            <p className="text-md mb-4">
              You can unlock the full Rainmaker experience by{' '}
              <InlineLink to="https://rainmakerfamily.com/mastermind/?el=courseonly">
                upgrading to our Rainmaker Mastermind
              </InlineLink>
              .
            </p>
          </>
        )}
        <div className="flex my-4 items-stretch h-80 md:h-96">
          <iframe
            src={`https://player.vimeo.com/video/720855226?h=d4e9b07569&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;autopause=0&amp;app_id=122963`}
            className="w-full h-full"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen=""
            title="Product Qualifier Intro"
            data-ready="true"
          />
        </div>
        <p className="mt-8">
          Run any idea through the Product Qualifier to ensure it's a WINNER before investing. Click
          "Learn More" next to each question for detailed walkthrough videos, or{' '}
          <InlineLink to="https://rainmakerlinks.com/how-to-product-qualifier">
            visit this lesson
          </InlineLink>{' '}
          to master the step-by-step process of using this tool.
        </p>
        <div className="pt-8">
          <h3 className="text-2xl">Questions</h3>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        <div>
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={(event) => {
              event.preventDefault()
              saveProductQualifierResultsAsync({ score: result, answers: formValues })
              setIsSubmitted(true)
            }}
          >
            <div className="space-y-8 divide-y divide-gray-200 text-lg">
              <div className="pt-4">
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="keyword"
                    name="keyword"
                    question={
                      <>
                        <span className="font-bold">1.</span> {PQ_QUESTIONS[0]}
                      </>
                    }
                    onLearnMore={() => setVideo('427237542')}
                  />
                </div>

                <fieldset className="mt-6">
                  <div className="flex items-center">
                    <legend className="text-md font-medium grow">
                      <span className="font-bold">2.</span> {PQ_QUESTIONS[1]}
                    </legend>
                    <LearnMoreButton onClick={() => setVideo('720855415')} />
                  </div>
                  <div className="mt-4 space-y-4">
                    <div className="w-full max-w-2xl relative">
                      <span
                        className="text-white bg-purple-450 text-xs rounded-full py-1.5 w-16 text-center absolute top-2.5"
                        style={{
                          left: `calc(${(formValues.volume / 50000) * 100}% - ${
                            27.5 + ((formValues.volume / 50000) * 100) / 10
                          }px)`
                        }}
                      >
                        {parseInt(formValues.volume).toLocaleString()}
                      </span>
                      <input
                        type="range"
                        value={formValues.volume || 0}
                        onChange={handleVolumeChange}
                        className="slider mt-14"
                        min="0"
                        max="50000"
                      />
                      <div className="flex justify-between">
                        <span>0</span>
                        <span>50,000+</span>
                      </div>
                    </div>
                    {formValues.volume > 0 && (
                      <div className="pb-4">
                        <h4 className="text-purple-450">Feedback</h4>
                        {formValues.volume > 0 && formValues.volume < 4000 && (
                          <p>Products below 4,000 may require additional keyword volume.</p>
                        )}
                        {formValues.volume > 4000 && formValues.volume < 25000 && (
                          <p>
                            You’re in the sweet spot! Most Rainmakers’ first products are within
                            this range.
                          </p>
                        )}
                        {formValues.volume > 25000 && (
                          <p>Products above 25,000 may require a higher budget.</p>
                        )}
                      </div>
                    )}
                  </div>
                </fieldset>

                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="competitors"
                    name="competitors"
                    question={
                      <>
                        <span className="font-bold">3.</span> {PQ_QUESTIONS[2]}
                      </>
                    }
                    onLearnMore={() => setVideo('427238028')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="sellers"
                    name="sellers"
                    question={
                      <>
                        <span className="font-bold">4.</span> {PQ_QUESTIONS[3]}
                      </>
                    }
                    onLearnMore={() => setVideo('427238123')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="price"
                    name="price"
                    question={
                      <>
                        <span className="font-bold">5.</span> {PQ_QUESTIONS[4]}
                      </>
                    }
                    onLearnMore={() => setVideo('720855385')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="units"
                    name="units"
                    question={
                      <>
                        <span className="font-bold">6.</span> {PQ_QUESTIONS[5]}
                      </>
                    }
                    onLearnMore={() => setVideo('720855462')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="alibaba"
                    name="alibaba"
                    question={
                      <>
                        <span className="font-bold">7.</span> With a quick search on Alibaba for
                        related products, <br /> can you get this product sourced for 4-5x less than
                        price on Amazon?
                      </>
                    }
                    onLearnMore={() => setVideo('427238513')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="cpr"
                    name="cpr"
                    question={
                      <>
                        <span className="font-bold">8.</span> {PQ_QUESTIONS[7]}
                      </>
                    }
                    onLearnMore={() => setVideo('720863997')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="xray"
                    name="xray"
                    question={
                      <>
                        <span className="font-bold">9.</span> {PQ_QUESTIONS[8]}
                      </>
                    }
                    onLearnMore={() => setVideo('427238933')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="improve"
                    name="improve"
                    question={
                      <>
                        <span className="font-bold">10.</span> {PQ_QUESTIONS[9]}
                      </>
                    }
                    onLearnMore={() => setVideo('427239032')}
                  />
                </div>
                <div className="mt-6">
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id="profit"
                    name="profit"
                    question={
                      <>
                        <span className="font-bold">11.</span> {PQ_QUESTIONS[10]}
                      </>
                    }
                    onLearnMore={() => setVideo('720855339')}
                    componentUnderQuestion={
                      isCourseOnly ? null : (
                        <p className="text-sm italic">
                          These margins provide a quick snapshot to assess if your product idea is
                          worth pursuing, saving you hours of your time. While aiming for a first
                          pass profit margin of 35-50% is optimal, don't forget to account for extra
                          expenses such as Amazon fees and PPC. For precise profitability
                          calculations, you can upgrade to{' '}
                          <InlineLink to="https://rainmakerupgrades.com/ppt-sheet">
                            Michelle's Profitability Calculator
                          </InlineLink>
                          , exclusively available to Mastermind students.
                        </p>
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="py-5">
              <div className="flex">
                <PrimaryButton type="submit" label="Rate My Product" extraClasses="uppercase" />
              </div>
            </div>
          </form>

          {isSubmitted && (
            <>
              <div className="my-5 max-w-xl">
                <h1 className="text-2xl font-bold">Results:</h1>
                <span className="text-3xl text-purple-450 mt-4 block">
                  {result}/11 — {resultEmojis}
                </span>
                <div className="relative mt-5" style={{ paddingTop: '56.25%' }}>
                  <iframe
                    src={`https://player.vimeo.com/video/${feedbackVideoId}?h=d4e9b07569&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;autopause=0&amp;app_id=122963`}
                    className="absolute inset-0 w-full h-full"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                    title="Rainmaker App Welcome 2"
                    data-ready="true"
                  ></iframe>
                </div>
              </div>

              <div className="py-12">
                {emailSent && (
                  <h2 className="text-lg mb-2 text-green-500">
                    The email has been sent successfully.
                  </h2>
                )}

                {!emailSent && (
                  <form
                    onSubmit={async (event) => {
                      event.preventDefault()
                      let answers = emailDefaults.answers
                      if (isUsingKeyword)
                        answers = appendKeywordToAnswers(emailDefaults.answers, keyword)
                      const action = await dispatch(
                        sendProductQualifierResultsAsync({
                          score: emailDefaults.score,
                          answers,
                          result: emailDefaults.result,
                          email: copyEmail
                        })
                      )
                      if (action.type.includes('fulfilled')) setEmailSent(true)
                    }}
                  >
                    <h2 className="text-lg mb-4">
                      Want a copy of this? Enter your email {isUsingKeyword ? 'and keyword ' : ''}
                      below:
                    </h2>
                    <label htmlFor="email">
                      Email
                      <input
                        className="appearance-none block w-full px-3 py-2 mt-2 mb-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm"
                        type="email"
                        id="email"
                        placeholder="Email address"
                        value={copyEmail}
                        onChange={(event) => {
                          setCopyEmail(event.target.value)
                        }}
                      />
                    </label>
                    {isUsingKeyword && (
                      <label htmlFor="keyword">
                        Keyword
                        <input
                          className="appearance-none block w-full px-3 py-2 mt-2 mb-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm"
                          type="text"
                          id="keyword"
                          placeholder="Optional: your top searched, closest-related keyword"
                          value={keyword}
                          onChange={(event) => {
                            setKeywords(event.target.value)
                          }}
                        />
                      </label>
                    )}
                    <button
                      type="submit"
                      className="w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450 uppercase"
                    >
                      Submit
                    </button>
                  </form>
                )}
              </div>
            </>
          )}
          <div className="my-6 pb-12">
            <h3 className="text-3xl pb-6">Need more feedback?</h3>
            <ul className="flex flex-col space-y-4 ml-6">
              <li>
                <span className="rounded-full bg-purple-450 px-2.5 py-1 text-white mr-2">1</span>
                Book a 2nd Eyes session{' '}
                <a
                  className="border-b border-purple-450 text-purple-450"
                  href="https://rainmakerupgrades.com/2ndeyes"
                  target="_blank"
                  rel="noreferrer"
                >
                  Here
                </a>
              </li>

              <li>
                <span className="rounded-full bg-purple-450 px-2.5 py-1 text-white mr-2">2</span>
                Share to the{' '}
                <a
                  className="border-b border-purple-450 text-purple-450"
                  href="https://www.facebook.com/groups/rainmakersvip/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook Mastermind Group
                </a>{' '}
                and get valuable feedback from our Rainmaker community.
              </li>

              <li>
                <span className="rounded-full bg-purple-450 px-2.5 py-1 text-white mr-2">3</span>
                Ask for a product review on a{' '}
                <a
                  className="border-b border-purple-450 text-purple-450"
                  href="https://app.rainmakers.academy/course/16/155"
                  target="_blank"
                  rel="noreferrer"
                >
                  Product Research Coaching Call
                </a>
              </li>
            </ul>
          </div>
        </div>
        <VideoModal open={!!video} setOpen={setVideo} video={video} />
      </div>
    </div>
  )
}

function appendKeywordToAnswers(answers, keyword) {
  const answersClone = [...answers]
  if (keyword) {
    answersClone[0] = { ...answersClone[0] } // fix immutable
    answersClone[0].answer = `Yes - ${keyword}`
  }
  return answersClone
}

export default ProductQualifier
