import React, { useState, useEffect, useRef, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import { clearError, upsertLessonAsync } from './courseSlice'
import { deleteFile, uploadFileAsync } from '../uploader/uploaderSlice'
import Select from 'react-select'
import { toast } from 'react-toastify'
import SecondaryButton from '../../components/general/SecondaryButton'
import LoadingSpinner from '../../components/general/LoadingSpinner'

const MDEditor = React.lazy(() => import('@uiw/react-md-editor'))

const LessonForm = ({ setState, setOpen, editingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { error, modules } = useSelector((state) => state.course)
  const { tags } = useSelector((state) => state.tag)

  useEffect(() => {
    if (editingItem) {
      setFormValues({ ...editingItem })
    } else {
      modules[0] &&
        setFormValues((prevState) => ({
          ...prevState,
          ModuleId: modules[0].id,
          position: 0
        }))
    }
    // eslint-disable-next-line
  }, [])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearError({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const [uploading, setUploading] = useState(false)

  // upload file listener
  const file = useRef(null)
  useEffect(() => {
    document.getElementById('upload').onclick = (event) => {
      document.getElementById('upload').value = null
    }

    document.getElementById('upload').onchange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
        setUploading(true)
        dispatch(uploadFileAsync({ id: 'upload' })).then(({ payload }) => {
          navigator.clipboard.writeText('![](' + payload.latestUploadedFileUrl + ')')
          toast.success('image URL copied to clipboard')
          setUploading(false)
        })
      }
    }

    return () => {
      dispatch(deleteFile())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <input id="upload" type="file" name="upload" ref={file} className="hidden" accept="image/*" />
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit lesson: ' + formValues.title : 'Add a new lesson'}
      </h3>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          await dispatch(upsertLessonAsync({ lesson: formValues }))
          setOpen(false)
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <div className="mt-1">
              <label htmlFor="ModuleId" className="block text-sm font-medium text-gray-700">
                Module
              </label>
              <select
                id="ModuleId"
                name="ModuleId"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                onChange={onChange}
                value={formValues?.ModuleId}
              >
                {modules.map((module) => (
                  <option key={module.id} value={module.id}>
                    {module.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Lesson title"
                field="title"
                error={error && error['title']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
              Required Tags
            </label>
            <div className="mt-1">
              <Select
                id="tags"
                isMulti={true}
                value={formValues?.Tags?.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
                onChange={(Tags) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    Tags: Tags.map((tag) => ({
                      id: tag.value,
                      name: tag.label
                    }))
                  }))
                }
                options={tags.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <div className="mt-1" data-color-mode="light">
              <Suspense fallback={<LoadingSpinner />}>
                <MDEditor
                  name="content"
                  value={formValues?.content || ''}
                  onChange={(value) =>
                    setFormValues((prevState) => ({
                      ...prevState,
                      content: value
                    }))
                  }
                  hideToolbar={false}
                  preview="edit"
                  autoFocus={true}
                  className={`text-lg border-2 rounded-md hover:border-purple-450`}
                  visibleDragbar={true}
                />
              </Suspense>
            </div>
          </div>
          <button
            type="button"
            disabled={uploading}
            onClick={(event) => {
              event.preventDefault()
              if (file && file.current) {
                file.current.click()
              }
            }}
            className="py-2 px-4 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450 sm:w-56"
          >
            Upload an image
          </button>
        </div>

        <div className="mt-12 py-3 sm:flex sm:justify-between">
          <div>
            {editingItem?.id && (
              <button
                type="button"
                className="uppercase w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="flex">
            <PrimaryButton extraClasses="sm:w-auto sm:ml-3 uppercase" label="Save" type="submit" />

            <SecondaryButton
              label="Cancel"
              extraClasses="sm:w-auto sm:ml-3 uppercase"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default LessonForm
