import React, { Suspense } from 'react'
import LoadingSpinner from './LoadingSpinner'

const Markdown = React.lazy(() =>
  import('@uiw/react-md-editor').then((module) => ({ default: module.default.Markdown }))
)

// updates the markdown to open links in a new tab
export const rehypeRewrite = (node) => {
  if (node.type === 'element' && node.tagName === 'a') {
    node.properties = { ...node.properties, target: '_blank', rel: 'noreferrer' }
  }
}

const MarkdownPreview = ({ markdown }) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Markdown source={markdown} rehypeRewrite={rehypeRewrite} />
    </Suspense>
  )
}

export default MarkdownPreview
