import { PencilIcon, PlusCircleIcon } from '@heroicons/react/solid'
import React from 'react'
import { useSelector } from 'react-redux'
import ResourceSearch from './ResourceSearch'

const ResourcesFilters = ({
  setResourceCategoryModalOpen,
  setEditingResourceCategory,
  viewingResource,
  onChangeFilters,
  filters,
  search,
  setSearch
}) => {
  const { currentUser } = useSelector((state) => state.auth)
  const { resourceCategories: categories } = useSelector((state) => state.resource)

  function onChangeType(event) {
    onChangeFilters({ ...filters, type: event.target.id })
  }

  function onToggleCategory(event, categoryId) {
    const categories = event.target.checked
      ? [...filters.categories, categoryId]
      : filters.categories.filter((c) => c !== categoryId)
    onChangeFilters({ ...filters, categories })
  }

  function onAddCategory() {
    setEditingResourceCategory({})
    setResourceCategoryModalOpen(true)
  }

  function onEditCategory(category) {
    setEditingResourceCategory(category)
    setResourceCategoryModalOpen(true)
  }

  return (
    <div className="w-full sm:w-72 px-2 py-6">
      {!viewingResource && (
        <>
          <fieldset className="px-4">
            <div className="mb-6">
              <ResourceSearch search={search} setSearch={setSearch} />
            </div>

            <div className="mb-6">
              <div className="relative flex items-start py-1">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor="all"
                    className="font-medium text-gray-700 select-none flex items-center"
                  >
                    All Resources
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id="all"
                    name="type"
                    type="radio"
                    className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                    value="all"
                    checked={'all' === filters?.type}
                    onChange={onChangeType}
                  />
                </div>
              </div>

              <div className="relative flex items-start py-1">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor="marketplace"
                    className="font-medium text-gray-700 select-none flex items-center"
                  >
                    Marketplace (Paid Upgrades)
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id="marketplace"
                    name="type"
                    type="radio"
                    className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                    checked={'marketplace' === filters?.type}
                    value="marketplace"
                    onChange={onChangeType}
                  />
                </div>
              </div>
              <div className="relative flex items-start py-1">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor="my-purchases"
                    className="font-medium text-gray-700 select-none flex items-center"
                  >
                    My Purchases
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id="my-purchases"
                    name="type"
                    type="radio"
                    className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                    value="my-purchases"
                    checked={'my-purchases' === filters?.type}
                    onChange={onChangeType}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <ResourceCategories
            categories={categories}
            onAddCategory={onAddCategory}
            onEditCategory={onEditCategory}
            onToggleCategory={onToggleCategory}
            filters={filters}
            isAdmin={currentUser?.role === 'admin'}
          />
        </>
      )}
    </div>
  )
}

function ResourceCategories({
  filters,
  onToggleCategory,
  categories,
  onAddCategory,
  onEditCategory,
  isAdmin
}) {
  return (
    <fieldset className="px-4">
      <legend className="text-lg font-medium text-gray-900 flex">
        Categories
        {isAdmin && (
          <button
            onClick={onAddCategory}
            className="px-4 ml-4 flex items-center justify-center bg-gray-200 hover:bg-pink-200 text-purple-450 text-xs py-2 rounded-md"
          >
            <PlusCircleIcon className="w-3 h-3 mr-1" />
            <span>Add Category</span>
          </button>
        )}
      </legend>
      <div className="mt-4">
        {categories.map((category) => (
          <div key={category.id} className="relative flex items-center py-1">
            <div className="min-w-0 flex-1 text-sm">
              <label
                htmlFor={`category-${category.id}`}
                className="font-medium text-gray-700 select-none flex items-center"
              >
                {category.name}
                {isAdmin && (
                  <button
                    onClick={() => onEditCategory(category)}
                    className="px-2 ml-4 flex items-center justify-center bg-gray-200 hover:bg-pink-200 text-purple-450 text-xs py-2 rounded-md cursor-pointer"
                    data-testid={`edit-category-${category.id}`}
                  >
                    <PencilIcon className="w-3 h-3 mr-1" />
                  </button>
                )}
              </label>
            </div>
            <div className="ml-3 flex items-center h-5">
              <input
                id={`category-${category.id}`}
                name="category"
                type="checkbox"
                className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                value={category.id}
                checked={filters?.categories.includes(category.id)}
                onChange={(event) => onToggleCategory(event, category.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

export default ResourcesFilters
