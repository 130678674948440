import React from 'react'
import PrimaryButton from '../../../components/general/PrimaryButton'
import { PencilIcon } from '@heroicons/react/outline'
import MetricItem from '../MetricItem'

const AllTab = ({
  products,
  setEditingItem,
  setMetricsViewProductId,
  setActiveTab,
  setProductOpen,
  setPromotionOpen,
  setAdvertisingOpen,
  setPlanningOpen
}) => {
  return (
    <div>
      {products?.map((product) => (
        <div key={product.id} className="flex flex-col mb-10">
          <div className="w-full rounded-md border py-6 px-6">
            <div className="flex justify-between items-center">
              <h1 className="text-4xl">{product.title}</h1>
              <button
                onClick={() => {
                  setEditingItem(product)
                  setProductOpen(true)
                }}
                className="inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
              >
                <PencilIcon className="h-5 w-5 mr-2" />
                edit
              </button>
            </div>
            <p className="mt-6">{product.description}</p>
          </div>

          <div className="sm:flex sm:space-x-8 mt-8">
            <div className="flex-1">
              <div className="grid grid-cols-3 items-center">
                <div></div>
                <h2 className="text-center uppercase" style={{ letterSpacing: '3px' }}>
                  Product
                </h2>
                <button
                  onClick={() => {
                    setEditingItem(product)
                    setProductOpen(true)
                  }}
                  className="justify-self-end w-24 inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
                >
                  <PencilIcon className="h-5 w-5 mr-2" />
                  edit
                </button>
              </div>
              <div className="rounded-md border flex flex-col items-center justify-center py-4 px-2">
                <div className="flex ">
                  <MetricItem
                    itemName="Potential Profit"
                    itemValue={product.potentialProfit}
                    dollar={true}
                  />
                  <div className="h-12 border-r border-black mt-12"></div>
                  <MetricItem
                    itemName="Profit Margin"
                    itemValue={product.profitMargin}
                    percentage={true}
                    tooltip="profit-margin-tooltip"
                  />
                </div>
                <div className="w-32 my-2">
                  <PrimaryButton
                    label="View Metrics"
                    onClick={() => {
                      setMetricsViewProductId(product.id)
                      setActiveTab('Product')
                      document.getElementById('main').scrollTo(0, 0)
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="grid grid-cols-3 items-center">
                <div></div>
                <h2 className="text-center uppercase" style={{ letterSpacing: '3px' }}>
                  Promotion
                </h2>
                <button
                  onClick={() => {
                    setEditingItem(product)
                    setPromotionOpen(true)
                  }}
                  className="justify-self-end w-24 inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
                >
                  <PencilIcon
                    className="h-5 w-5 mr-2"
                    onClick={() => {
                      setEditingItem(product)
                      setPromotionOpen(true)
                    }}
                  />
                  edit
                </button>
              </div>
              <div className="rounded-md border flex flex-col items-center justify-center py-4 px-2">
                <div className="flex items-center">
                  <MetricItem
                    itemName="Promotion Cost"
                    itemValue={product.promotionCost}
                    dollar={true}
                  />
                  <div className="h-12 border-r border-black"></div>
                  <MetricItem
                    itemName="Promotion Revenue"
                    itemValue={product.promotionRevenue}
                    dollar={true}
                  />
                </div>
                <div className="w-32 my-2">
                  <PrimaryButton
                    label="Edit Metrics"
                    onClick={() => {
                      setEditingItem(product)
                      setPromotionOpen(true)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sm:flex sm:space-x-8 mt-8">
            <div className="flex-1">
              <div className="grid grid-cols-3 items-center">
                <div></div>
                <h2 className="text-center uppercase" style={{ letterSpacing: '3px' }}>
                  Advertising
                </h2>
                <button
                  onClick={() => {
                    setEditingItem(product)
                    setAdvertisingOpen(true)
                  }}
                  className="justify-self-end w-24 inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
                >
                  <PencilIcon className="h-5 w-5 mr-2" />
                  edit
                </button>
              </div>
              <div className="rounded-md border flex flex-col items-center justify-center py-4 px-2">
                <div className="flex items-center">
                  <MetricItem
                    itemName="ACOS % Break Even"
                    itemValue={product.ACOSBreakEven}
                    percentage={true}
                  />
                  <div className="h-12 border-r border-black"></div>
                  <MetricItem
                    itemName="Campaign ACOS %"
                    itemValue={product.campaignACOS}
                    percentage={true}
                  />
                </div>
                <div className="w-32 my-2">
                  <PrimaryButton
                    label="Edit Metrics"
                    onClick={() => {
                      setEditingItem(product)
                      setAdvertisingOpen(true)
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="grid grid-cols-3 items-center">
                <div></div>
                <h2 className="text-center uppercase" style={{ letterSpacing: '3px' }}>
                  Planning
                </h2>
                <button
                  onClick={() => {
                    setEditingItem(product)
                    setPlanningOpen(true)
                  }}
                  className="justify-self-end w-24 inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
                >
                  <PencilIcon className="h-5 w-5 mr-2" />
                  edit
                </button>
              </div>
              <div className="rounded-md border flex flex-col items-center justify-center py-4 px-2">
                <div className="flex items-center">
                  <MetricItem
                    itemName="Monthly Gross"
                    itemValue={product.monthlyGross}
                    dollar={true}
                  />
                  <div className="h-12 border-r border-black"></div>
                  <MetricItem
                    itemName="Monthly Profit"
                    itemValue={product.monthlyProfit}
                    dollar={true}
                  />
                </div>
                <div className="w-32 my-2">
                  <PrimaryButton
                    label="Edit Metrics"
                    onClick={() => {
                      setEditingItem(product)
                      setPlanningOpen(true)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AllTab
