import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ClipboardCheckIcon, ChatIcon } from '@heroicons/react/outline'
import CardModal from './modal/CardModal'
import UpdateModal from './modal/UpdateModal'
import {
  deleteListAsync,
  moveCardsAsync,
  readManyLabelsAsync,
  readManyListsAsync,
  reorderCardsAsync,
  setActiveCardId
} from './moveTheNeedleSlice'
import NewCardForm from './card/NewCardForm'
import NewListForm from './list/NewListForm'
import EditListMenu from './list/EditListMenu'
import SecondaryButton from '../../components/general/SecondaryButton'
import { useLocation } from 'react-router-dom'
import FormModal from '../../components/general/FormModal'
import { forbiddenListNames } from '../../utils'
import ResetModal from './modal/ResetModal'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 20px 0`,
  ...draggableStyle
})

const MoveTheNeedle = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  // can be number if viewing someone else's board or null if own board
  const userId = parseInt(location.pathname.replace('/move-the-needle/', '')) || null

  const [pageUser, setPageUser] = useState(null)
  const [cardModalOpen, setCardModalOpen] = useState(false)
  const [activeList, setActiveList] = useState(null)
  const [resetModalOpen, setResetModalOpen] = useState(false)

  const dontShowMTNUpgrade = localStorage.getItem('dontShowMTNUpgrade')
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(dontShowMTNUpgrade ? false : true)

  const { currentUser } = useSelector((state) => state.auth)
  const { lists, loading } = useSelector((state) => state.moveTheNeedle)

  const [addingNewCard, setAddingNewCard] = useState(null)
  const [addingNewList, setAddingNewList] = useState(null)

  function onDragEnd(result) {
    const { draggableId, source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId

    if (sInd === dInd) {
      dispatch(
        reorderCardsAsync({
          ListId: sInd,
          startPosition: source.index,
          endPosition: destination.index
        })
      )
    } else {
      dispatch(
        moveCardsAsync({
          cardId: draggableId,
          sourceListId: sInd,
          destListId: dInd,
          positionOnSourceList: source.index,
          positionOnDestList: destination.index
        })
      )
    }
  }

  const loadMTN = () => {
    if (userId) {
      dispatch(readManyListsAsync({ userId }))
      dispatch(readManyLabelsAsync({ userId }))
    } else {
      dispatch(readManyListsAsync())
      dispatch(readManyLabelsAsync())
    }
  }

  useEffect(() => {
    loadMTN()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    try {
      if (userId) axios.get('/api/user/' + userId).then(({ data }) => setPageUser(data))
    } catch (err) {
      console.log(err)
    }
  }, [userId])

  const calculateTotalProgress = useCallback(() => {
    const MTNDataReduced = lists?.reduce((acc, board) => {
      switch (board.name) {
        case 'NOT STARTED':
        case 'IN PROGRESS':
        case 'COMPLETED':
        case 'ON HOLD':
          if (!acc['total']) {
            acc['total'] = board.Cards.length
          } else {
            acc['total'] += board.Cards.length
          }
          acc[board.name] = board.Cards.length
          break
        default:
          break
      }

      return acc
    }, {})

    return MTNDataReduced['COMPLETED'] === 0
      ? 0
      : Math.round(MTNDataReduced['COMPLETED'] / (MTNDataReduced.total / 100))
  }, [lists])

  const [deletingList, setDeletingList] = useState(null)
  const [deleteListModalOpen, setDeleteListModalOpen] = useState(false)

  const getUserName = () => {
    if (pageUser?.fullName) {
      // when signing in as
      return pageUser.fullName
    } else if (currentUser?.fullName) {
      return currentUser.fullName
    }
    // no name found
    return '-'
  }
  return (
    <>
      <div className="h-screen py-6">
        <div>
          <div className="mx-4 pb-4">
            <div className="flex justify-between">
              <div>
                <h1 className="text-2xl ">{getUserName()}'s Board</h1>
                <span>Total progress: {calculateTotalProgress() || 0}%</span>
              </div>

              <div>
                {'admin' !== currentUser.role && (
                  <button
                    className="hover:bg-gray-100 transition-colors py-2 px-4 rounded-md uppercase"
                    onClick={() => {
                      setResetModalOpen(true)
                    }}
                  >
                    reset board
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-1" style={{ height: '100%' }}>
            <div className="flex items-start space-x-6 mx-4 mb-12">
              <DragDropContext onDragEnd={onDragEnd}>
                {lists.map((list) => (
                  <Droppable key={list.id} droppableId={list.id.toString()}>
                    {(provided) => (
                      <div
                        className="w-96 border rounded-t-md bg-white"
                        style={{
                          borderColor: 'rgb(171 80 139 / 40%)'
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="flex justify-between bg-purple-450 text-white pl-4 pr-2 py-1.5 mb-4 uppercase rounded-t-md">
                          {list.name}
                          {'admin' === currentUser.role && !forbiddenListNames.includes(list.name) && (
                            <EditListMenu
                              list={list}
                              currentUser={currentUser}
                              // setIsRenamingList={setIsRenamingList}
                              // setEditingListName={setEditingListName}
                              setDeletingList={setDeletingList}
                              setDeleteListModalOpen={setDeleteListModalOpen}
                            />
                          )}
                        </div>
                        {list.Cards?.map((card, i) => (
                          <Draggable
                            isDragDisabled={loading}
                            key={card.id}
                            draggableId={card.id.toString()}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="px-3 "
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                onClick={() => {
                                  setCardModalOpen(true)
                                  dispatch(setActiveCardId({ activeCardId: card.id }))
                                  setActiveList(list)
                                }}
                              >
                                <div className="text-gray-500 flex flex-col border border-purple-450  bg-white shadow-md rounded-md">
                                  {card.coverPhotoUrl && (
                                    <div
                                      style={{
                                        backgroundImage: `url(${card.coverPhotoUrl})`
                                      }}
                                      className="rounded-md h-48 bg-no-repeat bg-cover bg-center"
                                    ></div>
                                  )}
                                  <div className="py-2 px-4">
                                    <div className="flex space-x-1">
                                      {card.Labels?.map((label) => (
                                        <span
                                          key={label.id}
                                          className={`inline-flex items-center px-4 py-1 rounded text-xs bg-${label.color}`}
                                        ></span>
                                      ))}
                                    </div>
                                    {card.title}
                                    <div className="flex my-3">
                                      {card.CheckLists.length > 0 && (
                                        <ClipboardCheckIcon className="h-6 w-6 -ml-1" />
                                      )}
                                      {card.Activities.length > 0 && (
                                        <ChatIcon className="h-6 w-6 ml-2" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <div
                          className="text-gray-500 text-center py-4 border-t"
                          style={{ borderColor: 'rgb(171 80 139 / 40%)' }}
                        >
                          {list.id === addingNewCard ? (
                            <NewCardForm list={list} setAddingNewCard={setAddingNewCard} />
                          ) : (
                            <SecondaryButton
                              label="Add another card"
                              onClick={() => setAddingNewCard(list.id)}
                              extraClasses="uppercase"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
              <div className="w-96 border rounded-t-md border-gray-300 bg-white h-36">
                <div className="text-gray-500 text-center py-4 border-t">
                  {addingNewList ? (
                    <NewListForm
                      setAddingNewList={setAddingNewList}
                      lists={lists}
                      userId={userId}
                    />
                  ) : (
                    <button
                      className="hover:bg-gray-100 transition-colors py-2 px-4 rounded-md uppercase"
                      onClick={() => setAddingNewList(true)}
                    >
                      Add another list
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <CardModal
            open={cardModalOpen}
            setOpen={setCardModalOpen}
            activeList={activeList}
            setActiveList={setActiveList}
          />
          <FormModal
            open={deleteListModalOpen}
            setOpen={setDeleteListModalOpen}
            defaultState="delete"
            editingItem={deletingList || {}}
            deleteAction={async () => {
              await dispatch(deleteListAsync({ id: deletingList.id }))
            }}
            deleteOnlyModal={true}
          />
          <UpdateModal open={upgradeModalOpen} onClose={() => setUpgradeModalOpen(false)} />
          <ResetModal
            loadMTN={loadMTN}
            resetModalOpen={resetModalOpen}
            setResetModalOpen={setResetModalOpen}
          />
        </div>
      </div>
    </>
  )
}

export default MoveTheNeedle
