import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'

const initialState = {
  loading: true,
  error: null,
  generalError: null,
  users: [],
  coaches: null,
  premiumCount: 0,
  basicCount: 0,
  totalCount: 0,
  CSVFoundUsers: [],
  CSVDuplicateUsers: [],
  CSVNotFoundUsers: []
}

export const readManyUsersAsync = createAsyncThunk(
  'user/readMany',
  async ({ page, usersPerPage, search, filters }) => {
    const { data } = await axios.get('/api/user/read-many', {
      params: {
        page,
        usersPerPage,
        search,
        filters
      }
    })

    return {
      users: data.users,
      premiumCount: data.premiumCount,
      basicCount: data.basicCount,
      totalCount: data.totalCount
    }
  }
)

export const getAllCoaches = createAsyncThunk('user/getAllCoaches', async () => {
  const { data } = await axios.get('/api/user/read-many', {
    params: {
      usersPerPage: 50,
      filters: {
        role: 'coach'
      }
    }
  })
  return data.users
})

export const upsertUserAsync = createAsyncThunk(
  'user/upsert',
  async ({ user }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/api/user/upsert`, user)
      return { user: data.user, isNew: data.isNew }
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const deleteUserAsync = createAsyncThunk('/user/delete', async ({ user }) => {
  await axios.delete(`/api/user/${user.id}`)
  return { id: user.id }
})

export const upgradeUsersRoleAsync = createAsyncThunk(
  'user/upgrade',
  async ({ users, newRole }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/api/user/upgrade-role`, { users, newRole })
      return { users, newRole }
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const exportUsersAsync = createAsyncThunk('user/export', async () => {
  const response = await axios({
    url: '/api/user/export',
    method: 'GET',
    responseType: 'blob' // important
  })
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'users.csv')
  document.body.appendChild(link)
  link.click()
})

export async function importUsersAsync(id) {
  const formData = new FormData()
  const file = document.getElementById(id)
  formData.append('file', file.files[0])
  await axios.post('/api/user/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export async function generateTestCSVAsync() {
  let url = '/api/user/generate-test-user-csv'
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob' // important
  })
  url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'users.csv')
  document.body.appendChild(link)
  link.click()
}

export async function generateTestImportCSVAsync() {
  let url = '/api/user/generate-test-user-import-csv'
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob' // important
  })
  url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'users.csv')
  document.body.appendChild(link)
  link.click()
}

export const validateCSVUsersAsync = createAsyncThunk(
  'user/validate-csv-users',
  async ({ users: userEmails } = {}) => {
    const subtractArray = (fromArray, removeArray) => {
      return fromArray?.reduce((acc, current) => {
        if (!removeArray.includes(current)) acc.push(current)
        return acc
      }, [])
    }
    const unique = (fromArray) => {
      return fromArray?.reduce((acc, current) => {
        if (!acc.includes(current)) acc.push(current)
        return acc
      }, [])
    }

    userEmails = userEmails.map((arr) => arr[0])

    const { data } = await axios.put(`/api/user/validate-users-csv`, {
      userEmails
    })

    return {
      found: subtractArray(unique(userEmails), data.notFound),
      notFound: unique(data.notFound),
      duplicates: unique(
        userEmails
          ?.reduce((acc, email) => {
            if (userEmails.filter((e) => e === email).length > 1) {
              acc.push(email)
            }
            return acc
          }, [])
          .sort()
      )
    }
  }
)

export const bulkUpdateUsersAsync = createAsyncThunk(
  'user/bulk-update',
  async ({ formValues, CSVFoundUsers } = {}, { rejectWithValue }) => {
    await axios.put(`/api/user/bulk-update`, {
      userEmails: CSVFoundUsers,
      tags: formValues?.Tags,
      availableAction: formValues?.availableAction,
      role: formValues?.role
    })
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null
    },
    clearErrors: (state) => {
      state.error = null
      state.generalError = null
    },
    setError: (state, action) => {
      state.error[action.payload.field] = action.payload.error
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(readManyUsersAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readManyUsersAsync.fulfilled, (state, action) => {
        state.loading = false
        state.users = action.payload.users
        state.basicCount = action.payload.basicCount
        state.premiumCount = action.payload.premiumCount
        state.totalCount = action.payload.totalCount
      })
      .addCase(readManyUsersAsync.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(upsertUserAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertUserAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          if (action.payload.user) {
            state.users.unshift(action.payload.user)
            toast.success(`User created successfully`)
          }
        } else {
          // update
          state.users = state.users?.map((user) =>
            user.id === action.payload.user.id ? action.payload.user : user
          )
          toast.success(`User updated successfully`)
        }
      })
      .addCase(upsertUserAsync.rejected, (state, action) => {
        state.loading = false
        if ('string' === typeof action.payload) {
          state.generalError = action.payload
        } else {
          state.error = (action.payload || []).reduce((acc, error) => {
            acc[error.path] =
              'users_email' === error.path
                ? `This email address is already in use`
                : `this field cannot be empty`
            return acc
          }, {})
        }
      })
      .addCase(deleteUserAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.loading = false
        state.users = state.users.filter((user) => user.id !== action.payload.id)
        toast.success(`User deleted successfully`)
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(upgradeUsersRoleAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upgradeUsersRoleAsync.fulfilled, (state, action) => {
        state.loading = false
        state.users = state.users.map((user) => {
          if (action.payload.users.includes(user.id)) {
            return { ...user, role: action.payload.newRole }
          } else {
            return user
          }
        })
        toast.success(`Updated ${action.payload.users.length} user(s) roles`)
      })
      .addCase(upgradeUsersRoleAsync.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(bulkUpdateUsersAsync.pending, (state) => {
        state.bulkUpdateLoading = true
      })
      .addCase(bulkUpdateUsersAsync.fulfilled, (state, action) => {
        state.bulkUpdateLoading = false
        toast.success(`Updated users sucessfully`)
      })
      .addCase(bulkUpdateUsersAsync.rejected, (state, action) => {
        toast.error(`There has been a problem processing the request`)
        state.bulkUpdateLoading = false
      })

      .addCase(validateCSVUsersAsync.pending, (state) => {
        state.bulkUpdateLoading = true
      })
      .addCase(validateCSVUsersAsync.fulfilled, (state, action) => {
        state.CSVFoundUsers = action.payload.found
        state.CSVDuplicateUsers = action.payload.duplicates
        state.CSVNotFoundUsers = action.payload.notFound
        state.bulkUpdateLoading = false
        toast.success(`Validated CSV users sucessfully`)
      })
      .addCase(validateCSVUsersAsync.rejected, (state, action) => {
        state.bulkUpdateLoading = false
        toast.error(`There was a problem validating CSV users`)
      })
      .addCase(getAllCoaches.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllCoaches.fulfilled, (state, action) => {
        state.loading = false
        state.coaches = action.payload
      })
      .addCase(getAllCoaches.rejected, (state) => {
        state.loading = false
      })
  }
})

export const { clearError, clearErrors, setError } = userSlice.actions
export default userSlice.reducer
