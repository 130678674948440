/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { useDispatch } from 'react-redux'
import { switchRole } from '../../features/auth/authSlice'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SwitchRoleSelect = ({ currentUser }) => {
  const [roles] = useState([
    {
      title: 'admin',
      description: 'Can manage the whole app',
      current: true
    },
    {
      title: 'coach',
      description:
        "Can review everyone's Move the Needle, see resources and see Premium Users in the User Dashboard section",
      current: false
    },
    {
      title: 'premium',
      description: 'Can view their own Move the Needle, premium PPT, and access premium resources',
      current: false
    },
    {
      title: 'course only',
      description: 'Can access course resources and premium PPT',
      current: false
    },
    {
      title: 'basic',
      description: 'Can access free resources and basic PPT',
      current: false
    }
  ])

  const dispatch = useDispatch()

  return (
    <div className="mb-4">
      <div className="mt-2 mb-1 mx-4 text-sm">Switch role:</div>
      <Listbox
        value={currentUser?.role}
        onChange={(selected) => dispatch(switchRole({ role: selected.title }))}
      >
        <Listbox.Label className="sr-only">Switch roles</Listbox.Label>
        <div className="relative mx-4">
          <div className="inline-flex shadow-sm rounded-md divide-x divide-purple-450">
            <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-purple-450">
              <div className="relative inline-flex items-center bg-purple-450 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                <p className="ml-2.5 text-sm font-medium">{currentUser?.role}</p>
              </div>
              <Listbox.Button className="relative inline-flex items-center bg-purple-450 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-purple-450 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-450">
                <span className="sr-only">Switch roles</span>
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </Listbox.Button>
            </div>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {roles.map((option) => (
                <Listbox.Option
                  key={option.title}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-purple-450' : 'text-gray-900',
                      'cursor-pointer select-none relative p-4 text-sm'
                    )
                  }
                  value={option}
                >
                  {({ active }) => (
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <p
                          className={
                            currentUser.role === option.title ? 'font-semibold' : 'font-normal'
                          }
                        >
                          {option.title?.toUpperCase()}
                        </p>
                        {currentUser.role === option.title ? (
                          <span className={active ? 'text-white' : 'text-purple-450'}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                      <p className={classNames(active ? 'text-white' : 'text-gray-500', 'mt-2')}>
                        {option.description}
                      </p>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default SwitchRoleSelect
