import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/user/userSlice'
import moveTheNeedleReducer from '../features/move-the-needle/moveTheNeedleSlice'
import uploaderReducer from '../features/uploader/uploaderSlice'
import courseReducer from '../features/course/courseSlice'
import tagReducer from '../features/tag/tagSlice'
import powerProfitToolReducer from '../features/power-profit-tool/powerProfitToolSlice'
import resourceReducer from '../features/resource/resourceSlice'
import stripeReducer from '../features/stripe/stripeSlice'
import productQualifierReducer from '../features/product-qualifier/productQualifierSlice'
import dashboardBannerReducer from '../features/dashboard/dashboardSlice'

export const reducer = {
  auth: authReducer,
  user: userReducer,
  uploader: uploaderReducer,
  moveTheNeedle: moveTheNeedleReducer,
  course: courseReducer,
  tag: tagReducer,
  powerProfitTool: powerProfitToolReducer,
  resource: resourceReducer,
  stripe: stripeReducer,
  productQ: productQualifierReducer,
  dashboardBanner: dashboardBannerReducer
}

export const store = configureStore({
  reducer
})

// This function is mostly intended for use in tests
export const createStoreWithPreloadedState = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState
  })
